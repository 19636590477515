import axios from "axios";

//// get all user apps list - in terminals

export const GetAllApps = (userEmail) => {
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/coin/terminal/user/registered/get`,
      {
        params: {
          email: userEmail,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

///// get all pairs

export const GetAllPairs = (appcode) => {
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/coin/terminal/app/pairs/quote/get?app_code=${appcode}`
      // {
      //   params: {
      //     app_code: appcode
      //   }
      // }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

//// get all pairs of quote

export const GetpairsOfQuote = (object) => {
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/coin/terminal/app/pairs/quote/get`,
      {
        params: object,
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

//// get all vaults

export const GetVaults = (object) => {
  try {
    let response = axios.get(
      `https://comms.globalxchange.io/coin/terminal/user/vault/balances/get`,
      {
        params: object,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

/// get all apps - user registered globally

export const GetAppsRegistered = (userEmail) => {
  try {
    const response = axios.get(
      `https://comms.globalxchange.io/gxb/apps/registered/user`,
      {
        params: {
          email: userEmail,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

///Get Balance For Non Terminal Vault

export const GetFromBalance = async (object) => {
  try {
    const response = await axios.post(
      `https://comms.globalxchange.io/coin/vault/service/coins/get`,
      object
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

///Fund Terminals Vault

export const VaultTransaction = async (object) => {
  console.log("zxczxczx", object)
  try {
    const response = await axios.post(
      `https://comms.globalxchange.io/coin/terminal/user/fund/vault`,
      object
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

///Market Buy or Sell Sending Quote or Base Currency

export const BuyOrSellCurrency = async (object) => {
  try {
    const response = await axios.post(
      `https://comms.globalxchange.io/coin/terminal/user/place/order`,
      object
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

////Get Trades

export const GetTrades = async (object) => {
  try {
    const response = axios.get(
      `https://apiterminals.apimachine.com/order-stats/lite-order-transactions?email=${object.email}&page=1&limit=${object.limit}`

    );
    return response;
  } catch (error) {
    return error.response;
  }
};

///// Get General Market News

export const GetMarketNews = async (object) => {
  try {
    const response = axios.get(`https://cryptonews-api.com/api/v1/category?`, {
      params: object,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

// start

export const GetPairApi = async (object) => {
  try {
    const response = axios.get(
      `https://apiterminals.apimachine.com/terminal/pair/byQuoteAsset/byTID/2d927dee-6063-49ee-b4cc-099afb3826cc`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};



export const GetAppsVaultBalace = (userEmail) => {
  try {
    const response = axios.get(
      `https://apiterminals.apimachine.com/userwallet/balance`,
      {
        headers: {
          email: userEmail,
        },
      }
    );
    console.log("zxncby4jd", userEmail)
    return response;
  } catch (error) {
    return error.response;
  }
};



export const GetVaultHeaxer = async (object) => {
  try {
    const response = axios.get(
      `https://terminalsapp.apimachine.com/vault-set/all?email=${object}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};



export const GetVaultNonMainValut = async (object) => {
  try {
    const response = axios.get(
      `https://apiterminals.apimachine.com/vault-set/balance?email=${object.email}&vaultSet=${object.path}&minimal=true`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};


export const FundVaultset = (userEmail) => {
  try {
    const response = axios.get(
      `https://apiterminals.apimachine.com/vault-set/user`,
      {
        headers: {
          email: userEmail,
        },
      }
    );
    console.log("zxncby4jd", userEmail)
    return response;
  } catch (error) {
    return error.response;
  }
};




export const GetVaultBalancePerson = async (object) => {
  try {
    const response = axios.get(
      `https://terminalsapp.apimachine.com/vault-set/balance?vaultSet=${object.set}&minimal=false&email=${object.email}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const BuyCoin = async (object) => {
  try {
    const response = await axios.post(
      `https://apiterminals.apimachine.com/order/place`,
      object,
      {
        headers: {
          email: JSON.parse(localStorage.getItem("user"))["data"].email,
          token: JSON.parse(localStorage.getItem("user"))["data"].token,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};


