import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "../../components/navbar/navbar";
import cross from '../../assets/images/cross.svg'
import success from '../../assets/images/success.svg'
import "./terminalRoutingPage.scss";
import { MainContext } from "../../components/Context/context";
import { useParams } from "react-router-dom";
import pngwrngicon from '../../assets/images/pngwrngicon.png'
import {
    GetAllPairs,
    GetVaults,
    BuyOrSellCurrency,
    GetTrades,
    GetpairsOfQuote,
    GetMarketNews,
    GetAppsVaultBalace,
    GetPairApi, FundVaultset,
    GetVaultHeaxer,
    GetVaultNonMainValut,
    GetVaultBalancePerson, BuyCoin,
} from "../../services/actionservice";
import searchicon from "../../assets/images/navbar/searchicon.svg";
import terminalImg from "../../assets/images/terminalImg.svg";
import fundsImg from "../../assets/images/fundsImg.svg";
import singal from "../../assets/images/singal.svg";
import { PieChart } from 'react-minimal-pie-chart';



import searchicon1 from "../../assets/images/navbar/searchicon1.svg";
import bitcoin from "../../assets/images/terminals/bitcoin.svg";
import fiat from "../../assets/images/terminals/fiat.svg";
import bonds from "../../assets/images/terminals/bonds.svg";
import sharetoken from "../../assets/images/terminals/sharetoken.svg";
import funds from "../../assets/images/terminals/funds.svg";
import binance from "../../assets/images/terminals/binance.svg";
import vaults from "../../assets/images/navbar/vaults.svg";
import vaults1 from "../../assets/images/navbar/vaults1.svg";
import Toggle from "../../components/toggleswitch/toggle";
import AddVault from "../../components/addVault/addVault";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import downarrow from "../../assets/images/downarrow.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import VaultDrawer from "./VaultDrawer";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
const events = require('events');
const eventEmitter = new events.EventEmitter();

eventEmitter.setMaxListeners(2);
// end

let ws = new WebSocket("wss://charts.apimachine.com");
// const startwebsocket = async(each) => {

//   console.log("kamal", prevpair, "now", each);

//   ws.onopen = (event) => {

//     ws.send(
//       JSON.stringify({
//         event: "joinBasePair",
//         data: {
//           basePair: each,
//         },
//       })
//     );

//         setprevpair(each)
//   };

const TerminalData = [
    {
        name: "Terminal",
        img: terminalImg,
    },
    {
        name: "Funds",
        img: fundsImg,
    },
    {
        name: "Signals",
        img: singal,
    },
]


const marketMenu = [
    {
        id: 0,
        menuItem: "Crypto",
        menuIcons: bitcoin,
        disabled: false,
    },
    {
        id: 1,
        menuItem: "Forex",
        menuIcons: fiat,
        disabled: true,
    },
    {
        id: 2,
        menuItem: "Bonds",
        menuIcons: bonds,
        disabled: true,
    },
    {
        id: 3,
        menuItem: "ShareTokens",
        menuIcons: sharetoken,
        disabled: true,
    },
    {
        id: 4,
        menuItem: "FundCoins",
        menuIcons: funds,
        disabled: true,
    },
];

const routingMenu = [
    {
        id: 0,
        menuItem: "Binance",
        menuIcons: binance,
    },
];

const profileMenu = [
    {
        id: 0,
        menuItem: "Profile",
        menuDesc: "Edit Your Personal Information",
    },
    {
        id: 1,
        menuItem: "Display Currency",
        menuDesc: "Currently US Dollars",
    },
    {
        id: 2,
        menuItem: "Theme",
        menuDesc: "Currently Light Mode",
    },
    {
        id: 3,
        menuItem: "Security",
        menuDesc: "Enhance Your Security",
    },
    {
        id: 4,
        menuItem: "KYC",
        menuDesc: "Currently Not Verified",
    },
    {
        id: 5,
        menuItem: "Logout",
        menuDesc: "Hope To See You Again Soon",
    },
];

const TerminalRoutingPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const {
        openpairs,
        setopenpairs,
        openrouting,
        setopenrouting,
        selectedurl,
        setselectedurl,
        selectedPair, vaultsetName,
        setselectedPair, setmobileButton, mobileButton,
        coin,
        setcoin,
        pairsList, setvaultsetName,
        setpairsList,
        buyValue,
        selectedNav,
        setselectedNav,
        check,
        setcheck,
        addVault,
        setaddVault,
        selectedVaultCoin,
        setSelectedVaultCoin,
        openuserProfile,
        setopenuserProfile,
        allpairs,
        setallpairs,
        settopbarPairCoin,
        topbarPairCoin,
        useremail,
        setCoinListed,
        setbuyValue,
        CoinListed,
        setsellValue,
        sellValue, setFundVaultsetDetail,
        checkedName, setPersonalValutSet,
        NameTabvault,
    } = useContext(MainContext);
    const [pairssearch, setpairssearch] = useState("");
    const [marketsearch, setmarketsearch] = useState("");
    const [routingsearch, setroutingsearch] = useState("");
    const [marketType, setmarketType] = useState({});
    const [routingType, setroutingType] = useState({});
    const [buyinput, setbuyinput] = useState("");
    const [sellinput, setsellinput] = useState("");
    const [btnselected, setbtnSelected] = useState("buy");
    const [tostSuceess, settostSuceess] = useState(false)
    const [SuceesMessage, setSuceesMessage] = useState({})
    const [selectedtradenav, setselectedtradenav] = useState("market");
    const [selectedfinalNav1, setselectedfinalNav1] = useState("My Funds");
    const [selectedfinalNav2, setselectedfinalNav2] = useState("Market News");
    const [selectedfinalNav3, setselectedfinalNav3] = useState("Trades");
    const [eventType, seteventType] = useState("");
    const [tickerData, settickerData] = useState({
        c: "--",
        o: "--",
        h: "--",
        l: "--",
        v: "--",
    });
    const [prevtickerData, setprevtickerData] = useState({
        c: "--",
        o: "--",
        h: "--",
        l: "--",
        v: "--",
    });
    const [orderbook, setorderbook] = useState({
        bids: [],
        asks: [],
    });
    const [prevpair, setprevpair] = useState("");
    const [sumofbids, setsumofbids] = useState(0);
    const [sumofasks, setsumofasks] = useState(0);
    const [vaultsearch, setvaultsearch] = useState("");
    const [coinvaults, setcoinvaults] = useState([]);
    const [isvaultLoading, setisvaultLoading] = useState(false);
    const [skel, setskel] = useState(["a", "b", "c", "d", "e", "f", "g", "h"]);
    const [quotebalance, setquotebalance] = useState("TBD");
    const [basebalance, setbasebalance] = useState("TBD");
    const [isbalanceLoading, setisbalanceLoading] = useState(false);
    const [opendropdown1, setopendropdown1] = useState(false);
    const [opendropdown2, setopendropdown2] = useState(false);
    const [selecteddrop1, setselecteddrop1] = useState("Amount");
    const [selecteddrop2, setselecteddrop2] = useState("Amount");
    const [settingsearch, setsettingsearch] = useState("");
    const [tradeList, settradeList] = useState([]);
    const [piedatalist, setpiedatalist] = useState([])
    const [ispairloading, setispairloading] = useState(false);
    const [newspage, setnewspage] = useState(1);
    const [allnews, setallnews] = useState([]);
    const [ismarketnewsLoading, setismarketnewsLoading] = useState(false);
    const [istradeLoading, setistradeLoading] = useState(false);

    // start
    const [pairDetail, setpairDetail] = useState([]);
    const [SelectedPairCoin, setSelectedPairCoin] = useState("");
    const [changePairName, setchangePairName] = useState("");

    const [orderNameCoinSell, setorderNameCoinSellSell] = useState("BTC");
    const [orderNameCoinBuy, setorderNameCoinBuy] = useState("BTC");
    const [PriceAvailble, setPriceAvailble] = useState("USDT");
    const [coinType, setcoinType] = useState("BTC");
    const [AvailbleBalanceBuy, setAvailbleBalanceBuy] = useState("USDT");
    const [AvailbleBalanceSell, setAvailbleBalanceSell] = useState("BTC");
    const [storeDefaultVault, setstoreDefaultVault] = useState([]);
    const [VaultHeaderStore, setVaultHeaderStore] = useState([]);
    const [MainVault, setMainVault] = useState("Main Vaut");
    const [NonVault, setNonVault] = useState([]);
    const [waitingToReconnect, setWaitingToReconnect] = useState(null);

    // valut
    const { base, quote } = useParams();

    useEffect(() => {
        let coin = localStorage.getItem("CoinDisplay")
        let price = coin.split("/");
        setPriceAvailble(price[1]);
        setorderNameCoinBuy(price[0]);
        setorderNameCoinSellSell(price[0]);
        setcoinType(price[0]);
        setAvailbleBalanceSell(price[0]);
        setAvailbleBalanceBuy(price[1]);
        setCoinListed(coin)
        // alert()
    }, [CoinListed])

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        if (window.innerWidth > 900) {
            setmobileButton("")
        }
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    console.log("mobileButton", mobileButton)

    console.log("selecteddrop1asdasd", selecteddrop1, selecteddrop2)

    const BuyCoinFunction = (e) => {
        let obj;
        // alert("pistadded")

        if (vaultsetName) {

            obj = {
                terminalId: "2d927dee-6063-49ee-b4cc-099afb3826cc",

                type: e,
                orderType: "market",

                amount:
                    e === "buy" ? parseFloat(buyinput) : parseFloat(sellinput),
                basePair: CoinListed,
                amountIn:
                    e === "buy" && selecteddrop1 === "Total"
                        ? "quote"
                        : e === "buy" && selecteddrop1 === "Amount"
                            ? "base"
                            : e === "sell" && selecteddrop2 === "Total"
                                ? "quote"
                                : "base",
                vaultSet: vaultsetName
            }

        }
        else {

            obj = {
                terminalId: "2d927dee-6063-49ee-b4cc-099afb3826cc",

                type: e,
                orderType: "market",

                amount:
                    e === "buy" ? parseFloat(buyinput) : parseFloat(sellinput),
                basePair: CoinListed,
                amountIn:
                    e === "buy" && selecteddrop1 === "Total"
                        ? "quote"
                        : e === "buy" && selecteddrop1 === "Amount"
                            ? "base"
                            : e === "sell" && selecteddrop2 === "Total"
                                ? "quote"
                                : "base",

            }
        }

        console.log("zxc24234ssfdf", obj)

        BuyCoin(obj).then((response) => {
            let result = response?.data;
            if (result.status) {
                // valutBalanceFunction1()
                callapiforsubmit()
                setSuceesMessage(result)
                settostSuceess(true)
                setbuyinput("");
                setsellinput("");
                tradecallhistroy(10)
                // NonmainVaultHandle(checkedName);
                // handlebalance(selectedPair.quote, "quote");
                // handlebalance(selectedPair.base, "base");
            } else {
                setisbalanceLoading(false);
                toast(result.message, {
                    type: "error", style: { backgroundColor: "#212A35", color: "white" }
                });
            }
            console.log("yueweiqeiqweiyqwieqwei", result);
        });
    };


    useEffect(() => {
        seteventType("");
    }, []);
    useEffect(() => {

        if (checkedName == "Main") {
            valutBalanceFunction1();
        } else if (
            NameTabvault == "Personal Vault Sets" &&
            checkedName !== "Main"
        ) {
            BalanceValue(checkedName);
        } else if (NameTabvault == "Fund Vault Sets" && checkedName !== "Main") {
            NonmainVaultHandle(checkedName);
        }

        // setcheckedName("Main")
    }, [CoinListed, checkedName]);

    const callapiforsubmit = () => {
        // alert(checkedName)
        if (checkedName == "Main") {
            valutBalanceFunction1();
        } else if (
            NameTabvault == "Personal Vault Sets" &&
            checkedName !== "Main"
        ) {
            BalanceValue(checkedName);
        } else if (NameTabvault == "Fund Vault Sets" && checkedName !== "Main") {
            NonmainVaultHandle(checkedName);
        }
    }

    const NonmainVaultHandle = (e) => {
        // alert("nin")
        let obj = {
            email: JSON.parse(localStorage.getItem("user"))["data"].email,
            path: e,
            minimal: true,
        };
        // setvaultsetName(e?.vault_set)
        let coin = CoinListed?.split("/");
        GetVaultNonMainValut(obj).then((response) => {
            let result = response?.data?.wallet;
            console.log("123cxzc", result)
            let filterQuote = result?.filter((item) => {
                return (
                    item?.ter_coin?.coin_code?.toUpperCase() === coin[1]?.toUpperCase()
                );
            });
            let filterBase = result?.filter((item) => {
                return (
                    item?.ter_coin?.coin_code?.toUpperCase() === coin[0]?.toUpperCase()
                );
            });
            setsellValue(filterBase?.[0]?.balance);
            setbuyValue(filterQuote?.[0]?.balance);
            //   setstoreDefaultVault(result);
            console.log("yueweiqeiqweiyqwieqwei", filterBase);
        });
    };

    const BalanceValue = (e) => {
        // setvaultTab("balaceVault");
        // alert("dayornight")
        let obj = {
            email: JSON.parse(localStorage.getItem("user"))["data"].email,
            set: e,
        };
        // setvalutInputName(`Vaults Personal ${e?.nickName}`);
        let coin = CoinListed?.split("/");
        GetVaultBalancePerson(obj).then((response) => {
            let result = response?.data?.wallet;
            console.log("zzzzzzzzzzzzzzz", result);

            let filterQuote = result?.filter((item) => {
                return item?.coinCode?.toUpperCase() === coin[1]?.toUpperCase();
            });
            let filterBase = result?.filter((item) => {
                return item?.coinCode?.toUpperCase() === coin[0]?.toUpperCase();
            });
            setsellValue(filterBase?.[0]?.balance);
            setbuyValue(filterQuote?.[0]?.balance);
            console.log("yueweiqeiqweiyqwieqwei", filterQuote);
        });
    };

    const valutBalanceFunction1 = () => {
        let obj = JSON.parse(localStorage.getItem("user"))["data"].email;
        // alert("main")
        setvaultsetName("")
        GetAppsVaultBalace(obj).then((response) => {
            let result = response?.data?.wallet;
            console.log("21312312312", CoinListed);
            let coin = CoinListed?.split("/");
            let filterQuote = result?.filter((item) => {
                return (
                    item?.ter_coin?.coin_code?.toUpperCase() === coin[1]?.toUpperCase()
                );
            });
            let filterBase = result?.filter((item) => {
                return (
                    item?.ter_coin?.coin_code?.toUpperCase() === coin[0]?.toUpperCase()
                );
            });
            console.log("333333333333333333", filterQuote);
            setsellValue(filterBase?.[0]?.balance);
            setbuyValue(filterQuote?.[0]?.balance);
            console.log("filter", filterQuote?.[0]?.balance);
        });
    };

    //end
    console.log("opendropdown2", opendropdown2);
    useEffect(() => {
        let obj = JSON.parse(localStorage.getItem("user"))["data"].email;

        GetPairApiFuntion("USDT");
        // valutBalanceFunction(obj);
        GetVaultHeaxerFuntion(obj);
        // alert("callfound")
        FundVaultset(obj).then((response) => {
            let result = response?.data;
            //   setPersonalValutSet(result);
            //   setstoreDefaultVault(result);

            setFundVaultsetDetail(result);
            // setcheckedName("")
            console.log("222222222", result);
        });

    }, []);

    //   const NonmainVaultHandle = (e, g) => {
    //     let objEmail = JSON.parse(localStorage.getItem("user"))["data"].email;
    //     setNonVault([]);
    //     setMainVault(e);
    //     if (e == "Main Vaut") {
    //       GetVaultHeaxerFuntion(objEmail);
    //     } else {
    //       let obj = {
    //         email: "baronamp25@gmail.com",
    //         path: g,
    //         minimal: true,
    //       };

    //       GetVaultNonMainValut(obj).then((response) => {
    //         let result = response?.data?.wallet;
    //         setNonVault(result);
    //         //   setstoreDefaultVault(result);
    //         console.log("111111111111", result);
    //       });
    //     }
    //   };

    const GetVaultHeaxerFuntion = (obj) => {
        // alert("hhehehe")
        GetVaultHeaxer(obj).then((response) => {
            let result = response?.data?.vaultsets;
            setVaultHeaderStore(result);
            setPersonalValutSet(result);
            //   setstoreDefaultVault(result);
            console.log("111111111111", result);
        });
    };

    const valutBalanceFunction = (obj) => {
        console.log("12314234", obj);
        GetAppsVaultBalace(obj).then((response) => {
            let result = response?.data?.wallet;
            setstoreDefaultVault(result);
        });
    };
    console.log("result123", storeDefaultVault);
    const handledropdown = (value, name) => {
        console.log("cxvxcvetfxvxc", value, name);
        // setopendropdown1(false);
        // setopendropdown2(false);
        // if (name === "dropdown1") {
        //   setselecteddrop1(value);

        // }

        // setselecteddrop2(value)

        if (name === "dropdown1" && value == "Total") {
            setselecteddrop1(value);
            let coin = CoinListed?.split("/");
            setorderNameCoinBuy(coin[1]);
        } else if (name === "dropdown1" && value == "Amount") {
            setselecteddrop1(value);
            let coin = CoinListed?.split("/");
            setorderNameCoinBuy(coin[0]);
        } else if (name === "dropdown2" && value == "Amount") {
            setselecteddrop2(value);
            let coin = CoinListed?.split("/");
            setorderNameCoinSellSell(coin[0]);
        } else if (name === "dropdown2" && value == "Total") {
            setselecteddrop2(value);
            let coin = CoinListed?.split("/");
            setorderNameCoinSellSell(coin[1]);
        }
    };

    console.log("selecteddrop1", orderbook);

    const functioncoinSeleted = async (e) => {
        await settickerData({
            c: "--",
            o: "--",
            h: "--",
            l: "--",
            v: "--",
        })
        await setsumofasks("");
        await setsumofbids("");
        await setorderbook([]);
        localStorage.setItem("CoinStorePair", e)
        // handleSelect()
        await setprevpair(CoinListed);
        // callfunctionclose()
        seteventType("close")
        await setCoinListed(e);
        localStorage.setItem("CoinDisplay", e);
        //  startwebsocket(e);
        // callfunctionclose()
        // callfunctionclose()
        console.log("xcxcv", e);
        let price = e.split("/");
        setPriceAvailble(price[1]);
        setcoinType(price[0]);
        setAvailbleBalanceBuy(price[1]);
        setAvailbleBalanceSell(price[0]);
        if (selecteddrop1 == "Amount" && selecteddrop2 == "Amount") {
            let coin = e.split("/");
            setorderNameCoinBuy(coin[0]);
            setorderNameCoinSellSell(coin[0]);
            //   seteventType("");
            //
        } else if (selecteddrop1 == "Total" && selecteddrop2 == "Total") {
            let coin = e.split("/");
            setorderNameCoinBuy(coin[1]);
            setorderNameCoinSellSell(coin[1]);
            //   seteventType("");
            //   startwebsocket(e)
        } else if (selecteddrop2 == "Amount" && selecteddrop1 == "Total") {
            let coin = e.split("/");
            setorderNameCoinSellSell(coin[0]);
            setorderNameCoinBuy(coin[1]);
            //   seteventType("");
            //   startwebsocket(e)
        } else if (selecteddrop2 == "Total" && selecteddrop1 == "Amount") {
            let coin = e.split("/");
            setorderNameCoinSellSell(coin[1]);
            setorderNameCoinBuy(coin[0]);
            //   seteventType("");
            //   startwebsocket(e)
        }

        setopenpairs(false);
    };
    const GetPairApiFuntion = (e) => {
        // alert(e)
        setSelectedPairCoin(e);
        GetPairApi().then((response) => {
            let result = response.data?.pairs;

            let handle = result?.filter((item) => {
                return item?.quote?.toUpperCase() === e?.toUpperCase();
            });
            settopbarPairCoin(result);
            setpairDetail(handle);
            console.log("result", handle);
        });
    };

    //   ebdddddddddddddddddddddddddddd

    const handlesetting = (each) => {
        if (each.menuItem === "Logout") {
            localStorage.removeItem("appList");
            // localStorage.removeItem('selectedappcode')
            localStorage.removeItem("pairurl");
            localStorage.removeItem("userdetails");
            localStorage.removeItem("selectedCoin");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("check");
            navigate(`/`);
        } else if (each.menuItem === "Theme") {
            let data = JSON.parse(localStorage.getItem("light"));
            localStorage.setItem("light", !data);
            window.location.reload();
        }
    };

    const handleScroll = (e) => {
        const bottom =
            parseInt(e.target.scrollHeight - e.target.scrollTop) >=
            e.target.clientHeight;
        if (bottom) {
            let page = newspage;
            setnewspage(page + 1);
        }
    };

    useEffect(() => {
        if (newspage === 1) {
            handlenews();
        } else {
            handlenews();
        }
    }, [newspage]);

    const handlenews = () => {
        setismarketnewsLoading(true);
        let obj = {
            section: "general",
            items: 10,
            page: newspage,
            token: "dm32yjdjeczk1kwtoamqvmirvgsu6zidz71u4uke",
        };
        GetMarketNews(obj).then((response) => {
            let result = response.data;
            if (result.data.length > 0) {
                if (allnews.length === 0) {
                    setallnews(result.data);
                    setismarketnewsLoading(false);
                } else {
                    let data = allnews;
                    data.push(...result.data);
                    setallnews(data);
                    setismarketnewsLoading(false);
                }
            }
        });
    };

    const tradecallhistroy = (e) => {
        setistradeLoading(true);
        let userData = JSON.parse(localStorage.getItem("user"));
        let obj = {
            email: userData.data.email,
            limit: e,
            // app_code: localStorage.getItem("selectedappcode"),
        };
        GetTrades(obj).then((response) => {
            let result = response.data;
            console.log("czxc123sc", result)
            // { title: 'One', value: 10, color: '#E38627' },
            settradeList(result.items);
            setistradeLoading(false);
            //  let pop= result?.items?.map(item=>{
            //   return{
            //     title:item?.
            //   }
            //  })
        });
    }

    useEffect(() => {
        tradecallhistroy(10)
    }, []);



    useEffect(() => {
        setisvaultLoading(true);
    }, [check]);

    const validateText = (text, name) => {
        let floatValues = /[+-]?([0-9]*[.])?[0-9]+/;
        if (text.match(floatValues) && !isNaN(text)) {
            handletradeInput(text, name);
        } else {
            let value = text.substring(0, text.length - 1);
            handletradeInput(value, name);
        }
    };

    const handlesetcoin = (each) => {
        setcoin(each);
    };

    const handletradeInput = (text, name) => {
        let t = text;
        let newValue = (t === "" ? "0.00" : t !== "" && t.indexOf(".") >= 0)
            ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 5)
            : t;
        if (name === "sell") {
            setsellinput(newValue);
        } else {
            setbuyinput(newValue);
        }
    };

    const handleDecimal = (num, key) => {
        if (num === "TBD") {
            num = 0;
        }
        num = num?.toString();
        num = num?.slice(0, num?.indexOf(".") + key);
        if (num !== "" && num !== "--") {
            return Number(num);
        } else {
            return "--";
        }
    };

    useEffect(() => {
        setispairloading(true);
        // if(Object.keys(coin).length > 0){
        let obj = {
            app_code: localStorage.getItem("selectedappcode"),
            quote: Object?.keys(coin)?.length > 0 ? coin?.symbol : "USDT",
        };
        GetpairsOfQuote(obj).then((response) => {
            let result = response.data;
            if (result.status) {
                setallpairs(result.coins[0]?.pairs);
                localStorage.setItem("allpairs", JSON.stringify(result.coins[0].pairs));
                setispairloading(false);
            }
        });
        // }
    }, [coin, localStorage.getItem("selectedappcode"), location]);

    //   useEffect(() => {
    //     // console.log(localStorage.getItem('pairurl'))
    //     // console.log(localStorage.getItem('selectedappcode'))
    //     if (localStorage.getItem("pairurl") !== "/select") {
    //       handleOpenPopup();
    //     } else {
    //       localStorage.setItem("pairurl", window.location.pathname);
    //     }
    //   }, [localStorage.getItem("selectedappcode"), location]);

    const handleClosePopup = () => {
        // setopenpairs(false)
        setopenrouting(false);
    };



    useEffect(() => {
        if (Object.keys(marketType).length === 0) {
            setmarketType(marketMenu[0]);
        }

        if (Object.keys(routingType).length === 0) {
            setroutingType(routingMenu[0]);
        }
    }, []);

    const handlerouting = (value, from) => {
        if (from === "Market" && value.menuItem === "Crypto") {
            setmarketType(value);
        } else if (from === "Routing") {
            // setroutingType(value)
            setselectedNav("Masters");
        }
        setopenrouting("");
    };
    let ws = new WebSocket("wss://charts.apimachine.com");
    const handleSelect = () => {
        // let unsubMsg = {
        //   type: "unsubscribe",
        //   product_ids: [pair],
        //   channels: ["ticker"]
        // };
        // let unsub = JSON.stringify(unsubMsg);


        ws.send(
            JSON.stringify({
                event: "leaveBasePair",
                data: {
                    basePair: "BTC/USDT",
                },
            })
        );


        setTimeout(() => {
            startwebsocket(CoinListed)
        }, 1000);

        // setpair(e.target.value);
    };




    useEffect(() => {
        if (waitingToReconnect) {
            return;
        }


        ws.onopen = (event) => {

            ws.send(
                JSON.stringify({
                    event: "joinBasePair",
                    data: {
                        basePair: CoinListed,
                    },
                })
            );

            setprevpair(CoinListed)
        };




        ws.onmessage = function (event) {

            console.log("czxwer", event.data)
            const finalData = JSON.parse(event.data);
            try {
                // console.log(finalData.tickerData_24hr, 'ram');
                if (Object.keys(finalData)[0] === "tickerData_24hr") {
                    console.log("121a3123", finalData.tickerData_24hr);
                    settickerData(finalData?.tickerData_24hr);
                }
                if (Object.keys(finalData)[0] === "orderbook") {
                    let sumask = 0;
                    let sumbid = 0;
                    finalData.orderbook.bids.forEach((x) => {
                        sumbid = sumbid + x[0] * x[1];
                    });
                    finalData.orderbook.asks.forEach((y) => {
                        sumask = sumask + y[0] * y[1];
                    });
                    setsumofasks(sumask);
                    setsumofbids(sumbid);
                    setorderbook(finalData.orderbook);
                }
            } catch (err) {
                console.log(err);
            }
        };


        ws.onclose = (event) => {

            if (waitingToReconnect) {
                return;
            };

            // Parse event code and log

            console.log('ws closed');

            // Setting this will trigger a re-run of the effect,
            // cleaning up the current websocket, but not setting
            // up a new one right away
            setWaitingToReconnect(true);

            // This will trigger another re-run, and because it is false,
            // the socket will be set up again
            setTimeout(() => setWaitingToReconnect(null), 5000);



            if (event.code === 4001) {
                console.log('yes maual closing');
            } else {
                ws = new WebSocket('wss://charts.apimachine.com');
                console.log('emit--- reconnect emit from forex');
                eventEmitter.emit(`orderbook-connected`);



                ws.onmessage = function (event) {
                    eventEmitter.emit('orderbook-message', event.data);
                    console.log("czxwer", event.data)
                    const finalData = JSON.parse(event.data);
                    try {

                        if (Object.keys(finalData)[0] === "tickerData_24hr") {
                            console.log("121a3123", finalData.tickerData_24hr);
                            settickerData(finalData.tickerData_24hr);
                        }
                        if (Object.keys(finalData)[0] === "orderbook") {
                            let sumask = 0;
                            let sumbid = 0;
                            finalData.orderbook.bids.forEach((x) => {
                                sumbid = sumbid + x[0] * x[1];
                            });
                            finalData.orderbook.asks.forEach((y) => {
                                sumask = sumask + y[0] * y[1];
                            });
                            setsumofasks(sumask);
                            setsumofbids(sumbid);
                            setorderbook(finalData.orderbook);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };

            }
            // ws = new WebSocket("wss://charts.apimachine.com");

            // seteventType("");
            // setTimeout(function() {
            //   // ws.close()
            //   startwebsocket(each);
            // }, 1000);
        };

        ws.onerror = () => {
            console.log("WS Error");
        };

        return () => {
            ws.close();
        };
    }, [CoinListed, waitingToReconnect]);



    const close = (e, event) => {
        if (e) {

        }
        else {
            if (event.code === 4001) {
                console.log('yes maual closing');
            } else {
                ws = new WebSocket('wss://charts.apimachine.com');
                console.log('emit--- reconnect emit from forex');

                // eventEmitter.emit(`orderbook-connected`);


                ws.onmessage = function (event) {

                    console.log("czxwer", event.data)
                    const finalData = JSON.parse(event.data);
                    try {
                        // console.log(finalData.tickerData_24hr, 'ram');
                        if (Object.keys(finalData)[0] === "tickerData_24hr") {
                            console.log("121a3123", finalData.tickerData_24hr);
                            settickerData(finalData.tickerData_24hr);
                        }
                        if (Object.keys(finalData)[0] === "orderbook") {
                            let sumask = 0;
                            let sumbid = 0;
                            finalData.orderbook.bids.forEach((x) => {
                                sumbid = sumbid + x[0] * x[1];
                            });
                            finalData.orderbook.asks.forEach((y) => {
                                sumask = sumask + y[0] * y[1];
                            });
                            setsumofasks(sumask);
                            setsumofbids(sumbid);
                            setorderbook(finalData.orderbook);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                };
                close(false);
            }
        }
    }




    const startwebsocket = async (each) => {

        console.log("kamal", prevpair, "now", each);

        ws.onopen = (event) => {

            ws.send(
                JSON.stringify({
                    event: "joinBasePair",
                    data: {
                        basePair: each,
                    },
                })
            );

            setprevpair(each)
        };




        ws.onmessage = function (event) {
            event.preventDefault()
            console.log("czxwer", event.data)
            const finalData = JSON.parse(event.data);
            try {
                // console.log(finalData.tickerData_24hr, 'ram');
                if (Object.keys(finalData)[0] === "tickerData_24hr") {
                    console.log("121a3123", finalData.tickerData_24hr);
                    settickerData(finalData.tickerData_24hr);
                }
                if (Object.keys(finalData)[0] === "orderbook") {
                    let sumask = 0;
                    let sumbid = 0;
                    finalData.orderbook.bids.forEach((x) => {
                        sumbid = sumbid + x[0] * x[1];
                    });
                    finalData.orderbook.asks.forEach((y) => {
                        sumask = sumask + y[0] * y[1];
                    });
                    setsumofasks(sumask);
                    setsumofbids(sumbid);
                    setorderbook(finalData.orderbook);
                }
            } catch (err) {
                console.log(err);
            }
        };


        ws.onclose = (event) => {
            // ws = new WebSocket("wss://charts.apimachine.com");

            // seteventType("");
            // setTimeout(function() {
            //   // ws.close()
            //   startwebsocket(each);
            // }, 1000);
        };



    };

    console.log("324c", prevpair)




    //   useEffect(() => {
    //     setselectedurl(window.location.pathname);
    //     navigate(window.location.pathname);
    //   }, [window.location.pathname]);

    useEffect(() => {
        if (selectedNav === "Vaults") {
            // handlevaults();
        }
    }, [selectedNav]);

    //   useEffect(() => {
    //     handlevaults();
    //   }, [check]);

    const handlevaults = () => {
        let userData = JSON.parse(localStorage.getItem("user"));
        let obj;
        if (check === "on") {
            obj = {
                email: userData.data.email,
                app_code: localStorage.getItem("selectedappcode"),
                displayCurrency: "USD",
            };
        } else {
            obj = {
                email: userData.data.email,
                app_code: localStorage.getItem("selectedappcode"),
                displayCurrency: "USD",
                withBalances: true,
            };
        }

        GetVaults(obj).then((response) => {
            let result = response.data;
            setcoinvaults(result?.coinVaults);
            setisvaultLoading(false);
        });
    };

    //   useEffect(() => {
    //     if (Object.keys(selectedPair).length > 0 && selectedNav !== "Vaults") {
    //       handlebalance(selectedPair.quote, "quote");
    //       handlebalance(selectedPair.base, "base");
    //     }

    //   }, [selectedPair]);

    // const handlebalance = (name, from) => {
    //   setisbalanceLoading(true);
    //   let userData = JSON.parse(localStorage.getItem("user"));
    //   let obj1 = {
    //     email: userData.data.email,
    //     app_code: localStorage.getItem("selectedappcode"),
    //     displayCurrency: "USD",
    //     coin: name,
    //   };
    //   GetVaults(obj1).then((response) => {
    //     let result = response.data;
    //     if (result.status && from === "quote") {
    //       setquotebalance(result?.coinVaults?.[0]?.vault);
    //     } else if (result.status && from === "base") {
    //       setbasebalance(result?.coinVaults?.[0]?.vault);
    //     }
    //     setisbalanceLoading(false);
    //   });
    // };


    const handleperc = (value, name) => {
        if (name === "buy") {
            let data = quotebalance === "TBD" ? 0 : quotebalance;
            let result = (data * value) / 100;
            setbuyinput(handleDecimal(result, 6));
        } else if (name === "sell") {
            let data = basebalance === "TBD" ? 0 : basebalance;
            let result = (data * value) / 100;
            setsellinput(handleDecimal(result, 6));
        }
    };

    // const handleBuyOrSell = (transtype) => {
    //   setisbalanceLoading(true);
    //   if (Object.keys(selectedPair).length > 0) {
    //     let obj = {
    //       app_code: localStorage.getItem("selectedappcode"),
    //       email: JSON.parse(localStorage.getItem("user"))["data"].email,
    //       token: JSON.parse(localStorage.getItem("user")).idToken,
    //       type: transtype,
    //       orderType: "market",
    //       basePair: selectedPair.pair,
    //       amount:
    //         transtype === "buy" ? parseFloat(buyinput) : parseFloat(sellinput),
    //       amountIn:
    //         transtype === "buy" && selecteddrop1 === "Total"
    //           ? "quote"
    //           : transtype === "buy" && selecteddrop1 === "Amount"
    //             ? "base"
    //             : transtype === "sell" && selecteddrop2 === "Total"
    //               ? "quote"
    //               : "base",
    //       stats: false,
    //     };

    //     BuyOrSellCurrency(obj).then((response) => {
    //       let result = response.data;
    //       if (result.status) {
    //         toast("Congratulations Transaction Completed Successfully", {
    //           type: "success",
    //         });
    //         setbuyinput("");
    //         setsellinput("");

    //         handlebalance(selectedPair.quote, "quote");
    //         handlebalance(selectedPair.base, "base");
    //       } else {
    //         setisbalanceLoading(false);
    //         toast(result.message, {
    //           type: "error",
    //         });
    //       }
    //     });
    //   }
    // };

    console.log(CoinListed?.replace("/", ""), "CoinListed")

    return (
        <>
            <ToastContainer />

            {windowSize.width <= 900 &&
                <div className="bottomMobileCustom">
                    <div
                        style={{
                            height: "2rem",
                            margin: "0"
                        }}
                        className="submit-trade-btn"
                        onClick={() => {
                            setselectedNav("Masters")
                            setmobileButton("buyMobile")
                        }}
                    >
                        <p>Buy {coinType}</p>
                    </div>
                    <div
                        style={{
                            height: "2rem",
                            margin: "0"
                        }}
                        className="submit-trade-btn1"
                        onClick={() => {
                            setselectedNav("Masters")
                            setmobileButton("sellMobile")
                        }}
                    // onClick={() => setmobileButton("sellMobile")}
                    // onClick={(e) => BuyCoinFunction("sell")}
                    >
                        <p>Sell {coinType}</p>
                    </div>
                </div>
            }

            <div
                className="terminal-master"
                style={{
                    backgroundColor: JSON.parse(localStorage.getItem("light"))
                        ? ""
                        : "#17181F",
                    color: JSON.parse(localStorage.getItem("light")) ? "" : "#E7E7E7",
                }}
            >
                <div
                    className="terminal-nav"
                    style={{
                        backgroundColor: JSON.parse(localStorage.getItem("light"))
                            ? ""
                            : "#17181F",
                    }}
                    onMouseDown={() => handleClosePopup()}
                >
                    <Navbar />
                </div>
                <div className="topTermial">
                    <div
                        className="terminal-nav-graphical"
                        style={{
                            backgroundColor: JSON.parse(localStorage.getItem("light"))
                                ? ""
                                : "#17181F",
                            borderColor: JSON.parse(localStorage.getItem("light"))
                                ? ""
                                : "#5F6163",
                        }}
                    >
                        <div className="each-price1">
                            <p
                                className="transaction-type-head"
                                onClick={() => {
                                    setopenpairs(!openpairs)
                                    setmobileButton("coinpair")
                                }
                                }
                            >
                                {CoinListed}
                                {/* {Object.keys(selectedPair).length > 0 ? selectedPair.pair : ""} */}
                            </p>
                        </div>
                        <div className="each-price">
                            <p className="transaction-small-head">Close</p>
                            <p className='transaction-value'>{handleDecimal(tickerData.c, 6)}</p>
                        </div>
                        <div className="each-price">
                            <p className='transaction-small-head'>Open</p>
                            <p className='transaction-value'>{handleDecimal(tickerData.o, 6)}</p>
                        </div>
                        <div className="each-price">
                            <p className="transaction-small-head">24Hr High</p>

                            <p className="transaction-value">
                                {handleDecimal(tickerData.h, 6)}
                            </p>
                        </div>
                        <div className="each-price">
                            <p className="transaction-small-head">24Hr Low</p>
                            <p className="transaction-value">
                                {handleDecimal(tickerData.l, 6)}
                            </p>
                        </div>
                        <div className="each-price">
                            <p className="transaction-small-head">24Hr Volume ({CoinListed?.split("/")?.[0]})</p>
                            <p className="transaction-value">
                                {handleDecimal(tickerData.v, 6)}
                            </p>
                        </div>
                        <div className="each-price">
                            <p className="transaction-small-head">24Hr Volume ({CoinListed?.split("/")?.[1]})</p>
                            <p className="transaction-value">
                                {handleDecimal(tickerData.q, 6)}
                            </p>
                        </div>
                    </div>
                    <div
                        className="terminal-body"
                        style={{
                            backgroundColor: JSON.parse(localStorage.getItem("light"))
                                ? ""
                                : "#17181F",
                        }}
                        onMouseDown={() => handleClosePopup()}
                    >
                        <div
                            className="terminal-graphical"
                            style={{
                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                    ? ""
                                    : "#17181F",
                            }}
                        >
                            <div
                                className="terminal-card"
                                style={{
                                    backgroundColor: JSON.parse(localStorage.getItem("light"))
                                        ? ""
                                        : "#17181F",
                                }}
                            >
                                <div
                                    className="all-stats-box"
                                    style={{
                                        backgroundColor: JSON.parse(localStorage.getItem("light"))
                                            ? ""
                                            : "#17181F",
                                        borderColor: JSON.parse(localStorage.getItem("light"))
                                            ? ""
                                            : "#5F6163",
                                    }}
                                >
                                    <div
                                        className="stats-1"
                                        style={{
                                            backgroundColor: "#131316",
                                            position: "relative",
                                            borderRadius: "8px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <TradingViewWidget
                                            symbol={`${base}${quote}`}
                                            theme={Themes.DARK}
                                            locale="en"
                                            autosize
                                        //  hide_side_toolbar={false}
                                        // allow_symbol_change={false}
                                        // hide_legend={true}
                                        />

                                    </div>
                                    <div
                                        className="stats-2"
                                        style={{
                                            backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                ? ""
                                                : "#17181F",
                                            borderColor: JSON.parse(localStorage.getItem("light"))
                                                ? ""
                                                : "#5F6163",
                                        }}
                                    >
                                        <div
                                            className="red-stats"
                                            style={{
                                                borderColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#5F6163",
                                            }}
                                        >
                                            <div
                                                className="stats-2-nav"
                                                style={{
                                                    paddingTop: "13px",
                                                    paddingBottom: "13px",
                                                    // background:
                                                    //   "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 33.1989%, rgba(239, 83, 80, 0.17) 33.1989%, rgba(239, 83, 80, 0.17) 100%)",
                                                }}
                                            >
                                                <div className="each-stat-nav">
                                                    <p className="stat-head1">Price</p>
                                                </div>
                                                <div className="each-stat-nav">
                                                    <p className="stat-head2">Amount</p>
                                                </div>
                                                <div className="each-stat-nav">
                                                    <p className="stat-head3">Total</p>
                                                </div>
                                            </div>
                                            <div
                                                className="stats-data"
                                                style={{
                                                    backgroundColor: JSON.parse(
                                                        localStorage.getItem("light")
                                                    )
                                                        ? ""
                                                        : "#17181F",
                                                }}
                                            >
                                                <>
                                                    {/* <div
                          className="each-stat-row"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 43.1989%, rgba(239, 83, 80, 0.17) 33.1989%, rgba(239, 83, 80, 0.17) 100%)",
                          }}
                        >
                          <p className="red-text-1a">1.00</p>
                          <p className="red-text-1b">8.00</p>
                          <p className="red-text-1c">15.35648</p>
                        </div>
                        <div
                          className="each-stat-row"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 54.1989%, rgba(239, 83, 80, 0.17) 33.1989%, rgba(239, 83, 80, 0.17) 100%)",
                          }}
                        >
                          <p className="red-text-1a">1.00</p>
                          <p className="red-text-1b">8.00</p>
                          <p className="red-text-1c">15.35648</p>
                        </div>
                        <div
                          className="each-stat-row"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 63.1989%, rgba(239, 83, 80, 0.17) 33.1989%, rgba(239, 83, 80, 0.17) 100%)",
                          }}
                        >
                          <p className="red-text-1a">1.00</p>
                          <p className="red-text-1b">8.00</p>
                          <p className="red-text-1c">15.35648</p>
                        </div>
                        <div
                          className="each-stat-row"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 73.1989%, rgba(239, 83, 80, 0.17) 33.1989%, rgba(239, 83, 80, 0.17) 100%)",
                          }}
                        >
                          <p className="red-text-1a">1.00</p>
                          <p className="red-text-1b">8.00</p>
                          <p className="red-text-1c">15.35648</p>
                        </div>
                        <div
                          className="each-stat-row"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 83.1989%, rgba(239, 83, 80, 0.17) 33.1989%, rgba(239, 83, 80, 0.17) 100%)",
                          }}
                        >
                          <p className="red-text-1a">1.00</p>
                          <p className="red-text-1b">8.00</p>
                          <p className="red-text-1c">15.35648</p>
                        </div> */}

                                                    {orderbook?.asks?.length > 0 ? (
                                                        <>
                                                            {orderbook?.asks?.reverse().map((each, id) => {
                                                                return (
                                                                    <div
                                                                        className="each-stat-row"
                                                                        key={id}
                                                                        style={{
                                                                            background: `linear-gradient(to right, ${JSON.parse(localStorage.getItem("light"))
                                                                                ? "#17181F"
                                                                                : "#17181F"
                                                                                } ${100 -
                                                                                (each[0] * each[1] * 100) / sumofasks
                                                                                }%,  rgba(239, 83, 80, 0.17) ${(each[0] * each[1] * 100) / sumofasks
                                                                                }%)`,
                                                                        }}
                                                                    >
                                                                        <p className="red-text-1a">
                                                                            {handleDecimal(each[0], 6)}
                                                                        </p>
                                                                        <p className="red-text-1b">
                                                                            {handleDecimal(each[1], 6)}
                                                                        </p>
                                                                        <p className="red-text-1c">
                                                                            {handleDecimal(each[0] * each[1], 6)}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                        <div
                                            className="price-stat-nav"
                                            style={{
                                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#17181F",
                                            }}
                                        >
                                            {/* <p
                      className="price-head"
                      style={{
                        color: "#5f6163;",
                      }}
                    >
                      ₹23.42
                    </p> */}
                                            <p className='price-head'
                                                style={{
                                                    color: tickerData.c === '--' ? '' :
                                                        prevtickerData.c === '--' ? '#5f6163' :
                                                            parseFloat(prevtickerData.c) <= parseFloat(tickerData.c) ? '#5f6163' : '#5f6163'
                                                }}>{handleDecimal(tickerData.c, 6)}</p>
                                        </div>
                                        <div
                                            className="green-stats"
                                        //   style={{
                                        //     backgroundColor: JSON.parse(localStorage.getItem("light"))
                                        //       ? ""
                                        //       : "#17181F",
                                        //   }}
                                        >
                                            <div
                                                className="each-stat-row"
                                                style={{
                                                    height: "10%",
                                                    // background:
                                                    //   "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 79.1989%, #3296CA78 33.1989%, #3296CA85 100%)",
                                                }}
                                            ></div>
                                            <div
                                                className="stats-data-green"
                                                style={{
                                                    backgroundColor: JSON.parse(
                                                        localStorage.getItem("light")
                                                    )
                                                        ? ""
                                                        : "#17181F",
                                                }}
                                            >
                                                <>
                                                    {" "}
                                                    <>
                                                        {orderbook?.bids?.length > 0 ? (
                                                            <>
                                                                {orderbook?.bids?.map((each, id) => {
                                                                    return (
                                                                        <div
                                                                            className="each-stat-row"
                                                                            key={id}
                                                                            style={{
                                                                                background: `linear-gradient(to right, ${JSON.parse(
                                                                                    localStorage.getItem("light")
                                                                                )
                                                                                    ? "#17181F"
                                                                                    : "#3296CA85"
                                                                                    } ${100 -
                                                                                    (each[0] * each[1] * 100) / sumofbids
                                                                                    }%,  ${JSON.parse(
                                                                                        localStorage.getItem("light")
                                                                                    )
                                                                                        ? "#3296CA85 "
                                                                                        : "#3296CA85"
                                                                                    } ${(each[0] * each[1] * 100) / sumofbids
                                                                                    }%)`,
                                                                            }}
                                                                        >
                                                                            <p className="green-text-1a">
                                                                                {handleDecimal(each[0], 6)}
                                                                            </p>
                                                                            <p className="green-text-1b">
                                                                                {handleDecimal(each[1], 6)}
                                                                            </p>
                                                                            <p className="green-text-1c">
                                                                                {handleDecimal(each[0] * each[1], 6)}
                                                                            </p>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="final-nav-box"
                                    style={{
                                        backgroundColor: JSON.parse(localStorage.getItem("light"))
                                            ? ""
                                            : "#17181F",
                                    }}
                                >
                                    <div
                                        className="final-box1"
                                        style={{
                                            backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                ? ""
                                                : "#17181F",
                                            borderColor: JSON.parse(localStorage.getItem("light"))
                                                ? ""
                                                : "#5F6163",
                                        }}
                                    >
                                        <div
                                            className="final-box-nav1"
                                            style={{
                                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#17181F",
                                                borderColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#5F6163",
                                            }}
                                        ><p
                                            className="final-text-nav"
                                            style={{
                                                opacity: selectedfinalNav1 === "My Funds" ? 1 : 0.25,
                                                color: selectedfinalNav1 === "My Funds" && "#3296CA"
                                            }}
                                            onClick={() => setselectedfinalNav1("My Funds")}
                                        >
                                                My Funds
                                            </p>

                                            <div
                                                style={{
                                                    borderRight: "0.5px solid #5F6163",
                                                    margin: "7px 0",
                                                }}
                                            ></div>


                                            <p
                                                className="final-text-nav"
                                                style={{
                                                    opacity: selectedfinalNav1 === "Top Traders" ? 1 : 0.25,
                                                    color: selectedfinalNav1 === "Top Traders" && "#3296CA"
                                                }}
                                                onClick={() => setselectedfinalNav1("Top Traders")}
                                            >
                                                Top Traders
                                            </p>
                                        </div>
                                        <div className="piechartlist">
                                            {/* <PieChart
  
        label={({ x, y, dx, dy, dataEntry }) => (
            <text
                x={x}
                y={y}
                dx={dx}
                dy={dy}
                dominant-baseline="central"
                text-anchor="middle"
                style={{
                    fill: '#fff', pointerEvents: 'none', fontSize: '3px'
                }}>
                <tspan x={x} y={y} dx={dx} dy={dy}>{dataEntry.title}</tspan>
                <tspan x={x} y={y+5} dx={dx} dy={dy}>{`${Math.round(dataEntry.value)}%`}</tspan>
            </text>
        )}
        data={[
          { title: 'One', value: 10, color: '#E38627' },
          { title: 'Two', value: 15, color: '#C13C37' },
          { title: 'Three', value: 20, color: '#6A2135' },
        ]}
    /> */}

                                        </div>
                                    </div>
                                    <div
                                        className="final-box2"
                                        style={{
                                            backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                ? ""
                                                : "#17181F",
                                            borderColor: JSON.parse(localStorage.getItem("light"))
                                                ? ""
                                                : "#5F6163",
                                        }}
                                    >
                                        <div
                                            className="final-box-nav2"
                                            style={{
                                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#17181F",
                                                borderColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#5F6163",
                                            }}
                                        >
                                            <p
                                                className="final-text-nav"
                                                style={{
                                                    opacity: selectedfinalNav2 === "Signals" ? 1 : 0.25,
                                                    color: selectedfinalNav2 === "Signals" && "#3296CA"
                                                }}
                                                onClick={() => setselectedfinalNav2("Signals")}
                                            >
                                                Signals
                                            </p>
                                            <div
                                                style={{
                                                    borderRight: "0.5px solid #5F6163",
                                                    margin: "7px 0",
                                                }}
                                            ></div>
                                            <p
                                                className="final-text-nav"
                                                style={{
                                                    opacity: selectedfinalNav2 === "Market News" ? 1 : 0.25,
                                                    color: selectedfinalNav2 === "Market News" && "#3296CA"
                                                }}
                                                onClick={() => setselectedfinalNav2("Market News")}
                                            >
                                                News
                                            </p>
                                        </div>
                                        <div
                                            className="final-box-body"
                                            style={{
                                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#17181F",
                                            }}
                                            onScroll={(e) => handleScroll(e)}
                                        >
                                            {/* <div
                                className={
                                  JSON.parse(localStorage.getItem("light"))
                                    ? "each-news-card"
                                    : "each-news-card-dark"
                                }
                              
                              >
                                <div className="each-news-img-box">
                                  <img
                                    className="each-news-img"
                                    src=""
                                    alt=""
                                  />
                                </div>
                                <div className="each-news-box">
                                  <p className="each-news-title">
                               sdf
                                  </p>
                                  <p className="each-news-desc">v</p>
                                  <p className="each-news-time">
                                 cv
                                  </p>
                                </div>
                              </div> */}
                                        </div>
                                    </div>
                                    <div
                                        className="final-box3"
                                        style={{
                                            backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                ? ""
                                                : "#17181F",
                                            borderColor: JSON.parse(localStorage.getItem("light"))
                                                ? ""
                                                : "#5F6163",
                                        }}
                                    >
                                        <div
                                            className="final-box-nav3"
                                            style={{
                                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#17181F",
                                                borderColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#5F6163",
                                            }}
                                        >
                                            <p
                                                className="final-text-nav"
                                                style={{
                                                    opacity: selectedfinalNav3 === "Trades" ? 1 : 0.25,
                                                    color: selectedfinalNav3 === "Trades" && "#3296CA"
                                                }}
                                                onClick={() => setselectedfinalNav3("Trades")}
                                            >
                                                Trades
                                            </p>
                                            <div
                                                style={{
                                                    borderRight: "0.5px solid #5F6163",
                                                    margin: "7px 0",
                                                }}
                                            ></div>
                                            <p
                                                className="final-text-nav"
                                                style={{
                                                    opacity: selectedfinalNav3 === "My Orders" ? 1 : 0.25,
                                                    color: selectedfinalNav3 === "My Orders" && "#3296CA"
                                                }}
                                                onClick={() => setselectedfinalNav3("My Orders")}
                                            >
                                                My Orders
                                            </p>
                                        </div>
                                        <div
                                            className="final-box-body"
                                            style={{
                                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#17181F",
                                            }}
                                        >
                                            {selectedfinalNav3 === "Trades" ? (
                                                <div
                                                    className="news-container"
                                                    style={{
                                                        backgroundColor: JSON.parse(
                                                            localStorage.getItem("light")
                                                        )
                                                            ? ""
                                                            : "#17181F",
                                                    }}
                                                >
                                                    <>
                                                        {istradeLoading ? (
                                                            <>
                                                                {skel.map((each, id) => {
                                                                    return (
                                                                        <div
                                                                            className="each-trade-card"
                                                                            style={{
                                                                                borderColor: JSON.parse(
                                                                                    localStorage.getItem("light")
                                                                                )
                                                                                    ? ""
                                                                                    : "#5F6163",
                                                                                backgroundColor: JSON.parse(
                                                                                    localStorage.getItem("light")
                                                                                )
                                                                                    ? ""
                                                                                    : "#17181F",
                                                                            }}
                                                                            key={id}
                                                                        >
                                                                            <div
                                                                                className="each-trade-menu"
                                                                                style={{
                                                                                    paddingTop: "18px",
                                                                                    backgroundColor: JSON.parse(
                                                                                        localStorage.getItem("light")
                                                                                    )
                                                                                        ? ""
                                                                                        : "#17181F",
                                                                                }}
                                                                            >
                                                                                <Skeleton
                                                                                    style={{
                                                                                        marginLeft: "15px",
                                                                                        height: "15px",
                                                                                        width: "250px",
                                                                                    }}
                                                                                />

                                                                            </div>
                                                                            <div
                                                                                className="each-trade-menu"
                                                                                style={{
                                                                                    backgroundColor: JSON.parse(
                                                                                        localStorage.getItem("light")
                                                                                    )
                                                                                        ? ""
                                                                                        : "#17181F",
                                                                                }}
                                                                            >


                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {tradeList.map((each, id) => {
                                                                    return (
                                                                        <div
                                                                            className={
                                                                                JSON.parse(localStorage.getItem("light"))
                                                                                    ? "each-trade-card"
                                                                                    : "each-trade-card-dark"
                                                                            }
                                                                            style={{
                                                                                borderColor: JSON.parse(
                                                                                    localStorage.getItem("light")
                                                                                )
                                                                                    ? ""
                                                                                    : "#5F6163",
                                                                                backgroundColor: JSON.parse(
                                                                                    localStorage.getItem("light")
                                                                                )
                                                                                    ? ""
                                                                                    : "#17181F",
                                                                            }}
                                                                            key={id}

                                                                        >
                                                                            <div className="each-trade-menu">
                                                                                <p className="each-trade-title">
                                                                                    {each.ter_coin_pair?.pair}
                                                                                </p>
                                                                                <p
                                                                                    className="each-trade-title"
                                                                                    style={{
                                                                                        color:
                                                                                            each.type === "buy"
                                                                                                ? "#3296CA"
                                                                                                : "#EF5350",
                                                                                    }}
                                                                                >
                                                                                    {Number(each.amount)?.toFixed(4)}
                                                                                </p>
                                                                            </div>
                                                                            <div className="each-trade-menu">
                                                                                <p className="each-trade-desc">
                                                                                    Price:
                                                                                </p>
                                                                                <p className="each-trade-desc">{`${handleDecimal(
                                                                                    each.meta_cut_price,
                                                                                    6
                                                                                )} ${each.ter_coin_pair?.pair?.split("/")?.[1]}`}</p>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </>
                                                        )}
                                                        <div className="Alltrade" onClick={() => tradecallhistroy(100)}>See All Trades</div>
                                                    </>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            style={
                                {
                                    position: window.innerWidth <= 900 ? "fixed" : "static",
                                    backgroundColor: JSON.parse(localStorage.getItem("light"))
                                        ? ""
                                        : "#17181F",
                                    borderColor: JSON.parse(localStorage.getItem("light"))
                                        ? ""
                                        : "#5F6163",
                                    display: window.innerWidth <= 900 && mobileButton == "" ? "none" : "block"
                                }
                            }
                            className="terminal-trade"

                        >
                            <>
                                {
                                    window.innerWidth <= 900 &&
                                    <img src={pngwrngicon} className="pngwrngicon"
                                        onClick={() => {
                                            setmobileButton("")
                                        }}
                                    />
                                }

                                {openpairs === true ? (
                                    <div>
                                        <div
                                            className="pairs-box-new"
                                            style={{
                                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#17181F",
                                            }}
                                        >
                                            <div className="each-new-coin">
                                                {topbarPairCoin?.map((item) => {
                                                    console.log(item, "mmmmmpop");

                                                    return (
                                                        <p
                                                            className="pairs-coin-text"
                                                            onClick={() => GetPairApiFuntion(item.quote)}
                                                        >
                                                            {SelectedPairCoin === item.quote ? (
                                                                <div className="borderPair"></div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {item.quote}
                                                        </p>
                                                    );
                                                })}

                                                {/* <p
                        className="pairs-coin-text"
                        onClick={() => GetPairApiFuntion("BTC")}
                      >
                        {SelectedPairCoin === "BTC" ? (
                          <div className="borderPair"></div>
                        ) : (
                          ""
                        )}
                        BTC
                      </p>
                      <p
                        className="pairs-coin-text"
                        onClick={() => GetPairApiFuntion("ETH")}
                      >
                        {SelectedPairCoin === "ETH" ? (
                          <div className="borderPair"></div>
                        ) : (
                          ""
                        )}
                        ETH
                      </p> */}
                                            </div>
                                        </div>
                                        <div
                                            class="nav-search-menu"
                                            style={{
                                                background: "#28313D",
                                                borderRadius: "5px",
                                                border: "none",
                                                width: "100%",

                                                /* padding: 0; */
                                                height: "3rem",
                                                padding: "0px 15px",
                                            }}
                                        >
                                            <div class="nav-search-box">
                                                <img class="search-nav-img" src={searchicon} alt="" />
                                                <input
                                                    className="inputpairSearch"
                                                    placeholder="Search For Trading Pairs..."
                                                    value={changePairName}
                                                    onChange={(e) => setchangePairName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="basecurrency">Base Currency</div>
                                        <div className="pairInfo">
                                            {pairDetail?.length > 0 ? (
                                                <>
                                                    {pairDetail?.map((item) => {
                                                        return item?.pairs
                                                            ?.filter((friend) =>
                                                                friend.pair
                                                                    ?.toLowerCase()
                                                                    .includes(changePairName.toLowerCase())
                                                            )
                                                            ?.map((item) => {
                                                                return (
                                                                    <div
                                                                        className="pair-item"
                                                                        onClick={() => functioncoinSeleted(item.pair)}
                                                                    >
                                                                        {item?.pair}
                                                                    </div>
                                                                );
                                                            });
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    <Skeleton
                                                        className="selektonloader"
                                                        style={{
                                                            marginBottom: "1rem",
                                                            height: "42px",
                                                            background: " #80808061",
                                                            width: "100%",
                                                        }}
                                                    />
                                                    <Skeleton
                                                        className="selektonloader"
                                                        style={{
                                                            marginBottom: "1rem",
                                                            height: "42px",
                                                            background: " #80808061",
                                                            width: "100%",
                                                        }}
                                                    />
                                                    <Skeleton
                                                        className="selektonloader"
                                                        style={{
                                                            marginBottom: "1rem",
                                                            height: "42px",
                                                            background: " #80808061",
                                                            width: "100%",
                                                        }}
                                                    />{" "}
                                                    <Skeleton
                                                        className="selektonloader"
                                                        style={{
                                                            marginBottom: "1rem",
                                                            height: "42px",
                                                            background: " #80808061",
                                                            width: "100%",
                                                        }}
                                                    />{" "}
                                                    <Skeleton
                                                        className="selektonloader"
                                                        style={{
                                                            marginBottom: "1rem",
                                                            height: "42px",
                                                            background: " #80808061",
                                                            width: "100%",
                                                        }}
                                                    />{" "}
                                                    <Skeleton
                                                        className="selektonloader"
                                                        style={{
                                                            marginBottom: "1rem",
                                                            height: "42px",
                                                            background: " #80808061",
                                                            width: "100%",
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ) : openuserProfile === true ? (
                                    <div
                                        className="profile-menu"
                                        style={{
                                            backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                ? ""
                                                : "#17181F",
                                        }}
                                    >
                                        <div
                                            className="settings-searchbox"
                                            style={{
                                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#17181F",
                                                borderColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#5F6163",
                                            }}
                                        >
                                            <input
                                                className="set-searchbox"
                                                style={{
                                                    backgroundColor: JSON.parse(
                                                        localStorage.getItem("light")
                                                    )
                                                        ? ""
                                                        : "#17181F",
                                                }}
                                                type="search"
                                                placeholder="Search Settings..."
                                                value={settingsearch}
                                                onChange={(e) => setsettingsearch(e.target.value)}
                                            />
                                        </div>
                                        <div
                                            className="setting-menu-box"
                                            style={{
                                                backgroundColor: JSON.parse(localStorage.getItem("light"))
                                                    ? ""
                                                    : "#17181F",
                                            }}
                                        >
                                            <>
                                                {profileMenu
                                                    ?.filter((element) => {
                                                        return element.menuItem
                                                            .toLowerCase()
                                                            .includes(settingsearch.toLowerCase());
                                                    })
                                                    .map((each, id) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    className="each-setting-menu"
                                                                    style={{
                                                                        borderColor: JSON.parse(
                                                                            localStorage.getItem("light")
                                                                        )
                                                                            ? ""
                                                                            : "#5F6163",
                                                                    }}
                                                                    onClick={(e) => handlesetting(each)}
                                                                >
                                                                    <p className="setting-head">{each.menuItem}</p>
                                                                    <p className="setting-desc">{each.menuDesc}</p>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                            </>
                                        </div>
                                    </div>
                                ) : selectedNav === "Masters" ? (
                                    <>
                                        <>
                                            <div
                                                className="trade-nav"
                                                onMouseDown={() => handledropdown("", "")}
                                                style={{
                                                    backgroundColor: JSON.parse(
                                                        localStorage.getItem("light")
                                                    )
                                                        ? ""
                                                        : "#17181F",
                                                    borderColor: JSON.parse(localStorage.getItem("light"))
                                                        ? ""
                                                        : "#5F6163",
                                                }}
                                            >
                                                <div
                                                    className="each-price2"
                                                    style={{
                                                        fontWeight: selectedtradenav === "market" ? 700 : "",
                                                    }}
                                                    onClick={() => setselectedtradenav("market")}
                                                >
                                                    <p className="trade-head">Market</p>
                                                    <div className="borderTrading"></div>
                                                </div>
                                                <div
                                                    className="each-price2"
                                                    style={{
                                                        fontWeight: selectedtradenav === "Limit" ? 700 : "",
                                                    }}
                                                    onClick={() => setselectedtradenav("Limit")}
                                                >
                                                    <p className="trade-head">Limit</p>
                                                </div>
                                                <div
                                                    className="each-price2"
                                                    style={{
                                                        fontWeight:
                                                            selectedtradenav === "Stop Limit" ? 700 : "",
                                                    }}
                                                    onClick={() => setselectedtradenav("Stop Limit")}
                                                >
                                                    <p className="trade-head">Stop Limit</p>
                                                </div>
                                            </div>
                                            <div
                                                className="trade-body"
                                                style={{
                                                    backgroundColor: JSON.parse(
                                                        localStorage.getItem("light")
                                                    )
                                                        ? ""
                                                        : "#17181F",
                                                }}
                                                onMouseDown={() => handledropdown("", "")}
                                            >
                                                {/* mobilever */}
                                                {
                                                    window.innerWidth <= 900 && mobileButton &&
                                                    <>
                                                        {
                                                            window.innerWidth <= 900 && mobileButton == "buyMobile" &&
                                                            <>
                                                                <div
                                                                    className="buy-section"
                                                                    style={{
                                                                        borderColor: JSON.parse(localStorage.getItem("light"))
                                                                            ? ""
                                                                            : "#5F6163",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="trade-head-div"
                                                                        style={{
                                                                            backgroundColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#17181F",
                                                                        }}
                                                                    >
                                                                        <p
                                                                            className="trade-head-inner"
                                                                            style={{
                                                                                backgroundColor: JSON.parse(
                                                                                    localStorage.getItem("light")
                                                                                )
                                                                                    ? ""
                                                                                    : "#17181F",
                                                                            }}
                                                                        >
                                                                            Buy
                                                                        </p>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <div
                                                                                className="small-btn-1"
                                                                                style={{
                                                                                    cursor:
                                                                                        selecteddrop1 === "Total"
                                                                                            ? ""
                                                                                            : "not-allowed",
                                                                                    borderColor: JSON.parse(
                                                                                        localStorage.getItem("light")
                                                                                    )
                                                                                        ? ""
                                                                                        : "#5F6163",
                                                                                }}
                                                                                onClick={() =>
                                                                                    selecteddrop1 === "Total"
                                                                                        ? handleperc(100, "buy")
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                100%
                                                                            </div>
                                                                            <div
                                                                                className="small-btn-1"
                                                                                style={{
                                                                                    borderColor: JSON.parse(
                                                                                        localStorage.getItem("light")
                                                                                    )
                                                                                        ? ""
                                                                                        : "#5F6163",
                                                                                }}
                                                                            >
                                                                                Custom
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="trade-head-div-2"
                                                                        style={{
                                                                            backgroundColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#17181F",
                                                                        }}
                                                                    >
                                                                        <p className="head-2"
                                                                            style={{ fontSize: "9px" }}
                                                                        >Avbl:</p>
                                                                        <>
                                                                            <p className="head-2">
                                                                                {buyValue == undefined
                                                                                    ? Number(0.0)?.toFixed(4)
                                                                                    : Number(buyValue)?.toFixed(4)} &nbsp;
                                                                                {AvailbleBalanceBuy}
                                                                            </p>
                                                                        </>
                                                                    </div>
                                                                    <div
                                                                        className="trade-box-1"
                                                                    //   style={{
                                                                    //     backgroundColor: JSON.parse(
                                                                    //       localStorage.getItem("light")
                                                                    //     )
                                                                    //       ? ""
                                                                    //       : "#17181F",
                                                                    //     borderColor: JSON.parse(
                                                                    //       localStorage.getItem("light")
                                                                    //     )
                                                                    //       ? ""
                                                                    //       : "#5F6163",
                                                                    //   }}
                                                                    >
                                                                        <p className="trade-box-part1">Price</p>
                                                                        <div className="trading-flex-pair">
                                                                            <p className="trade-box-part2">Market</p>
                                                                            <p className="trade-box-part3">{PriceAvailble}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="trade-box-1"
                                                                    //   style={{
                                                                    //     backgroundColor: JSON.parse(
                                                                    //       localStorage.getItem("light")
                                                                    //     )
                                                                    //       ? ""
                                                                    //       : "#17181F",
                                                                    //     borderColor: JSON.parse(
                                                                    //       localStorage.getItem("light")
                                                                    //     )
                                                                    //       ? ""
                                                                    //       : "#5F6163",
                                                                    //   }}
                                                                    >
                                                                        <div
                                                                            className="trade-box-part1-new"
                                                                            // style={{
                                                                            //   backgroundColor: JSON.parse(
                                                                            //     localStorage.getItem("light")
                                                                            //   )
                                                                            //     ? ""
                                                                            //     : "#17181F",
                                                                            //   borderColor: JSON.parse(
                                                                            //     localStorage.getItem("light")
                                                                            //   )
                                                                            //     ? ""
                                                                            //     : "#5F6163",
                                                                            // }}
                                                                            // onClick={(e) => handledrop("dropdown1")}

                                                                            onClick={
                                                                                opendropdown1
                                                                                    ? (e) => {
                                                                                        handledropdown("Amount", "dropdown1");
                                                                                        setopendropdown1(false);
                                                                                    }
                                                                                    : (e) => {
                                                                                        handledropdown("Total", "dropdown1");
                                                                                        setopendropdown1(true);
                                                                                    }
                                                                            }
                                                                        >
                                                                            <p>{opendropdown1 ? "Total" : "Amount"}</p>
                                                                            {/* <p>{selecteddrop1}</p> */}
                                                                            <img
                                                                                className="dropdown-icon"
                                                                                src={downarrow}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="trading-flex-pair">
                                                                            <input
                                                                                className="trade-box-part2"
                                                                                type="text"
                                                                                placeholder="0.00"
                                                                                value={buyinput}
                                                                                onChange={(e) =>
                                                                                    validateText(e.target.value, "buy")
                                                                                }
                                                                            />
                                                                            <p className="trade-box-part3">
                                                                                {orderNameCoinBuy}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="submit-trade-btn"
                                                                        onClick={(e) => BuyCoinFunction("buy")}
                                                                    >
                                                                        <p>Buy {coinType}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {
                                                            window.innerWidth <= 900 && mobileButton == "sellMobile" &&

                                                            <>
                                                                <div
                                                                    className="sell-section"
                                                                    style={{
                                                                        backgroundColor: JSON.parse(
                                                                            localStorage.getItem("light")
                                                                        )
                                                                            ? ""
                                                                            : "#17181F",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="trade-head-div"
                                                                        style={{
                                                                            backgroundColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#17181F",
                                                                        }}
                                                                    >
                                                                        <p className="trade-head-inner">Sell</p>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <div
                                                                                className="small-btn-1"
                                                                                style={{
                                                                                    cursor:
                                                                                        selecteddrop2 === "Total"
                                                                                            ? "not-allowed"
                                                                                            : "",
                                                                                    borderColor: JSON.parse(
                                                                                        localStorage.getItem("light")
                                                                                    )
                                                                                        ? ""
                                                                                        : "#5F6163",
                                                                                }}
                                                                                onClick={() =>
                                                                                    selecteddrop2 === "Total"
                                                                                        ? ""
                                                                                        : handleperc(100, "sell")
                                                                                }
                                                                            >
                                                                                100%
                                                                            </div>
                                                                            <div
                                                                                className="small-btn-1"
                                                                                style={{
                                                                                    borderColor: JSON.parse(
                                                                                        localStorage.getItem("light")
                                                                                    )
                                                                                        ? ""
                                                                                        : "#5F6163",
                                                                                }}
                                                                            >
                                                                                Custom
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="trade-head-div-2">
                                                                        <p className="head-2"
                                                                            style={{ fontSize: "9px" }}
                                                                        >Avbl:</p>
                                                                        <>
                                                                            <p className="head-2">
                                                                                {sellValue == undefined
                                                                                    ? Number(0.0)?.toFixed(4)
                                                                                    : Number(sellValue)?.toFixed(4)}{" "}&nbsp;
                                                                                {AvailbleBalanceSell}
                                                                            </p>
                                                                        </>
                                                                    </div>
                                                                    <div
                                                                        className="trade-box-1"
                                                                        style={{
                                                                            backgroundColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#17181F",
                                                                            borderColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#5F6163",
                                                                        }}
                                                                    >
                                                                        <p className="trade-box-part1">Price</p>
                                                                        <p className="trade-box-part2">Market</p>
                                                                        <p className="trade-box-part3">{PriceAvailble}</p>
                                                                    </div>
                                                                    <div
                                                                        className="trade-box-1"
                                                                        style={{
                                                                            backgroundColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#17181F",
                                                                            borderColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#5F6163",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="trade-box-part1-new"
                                                                            style={{
                                                                                borderColor: JSON.parse(
                                                                                    localStorage.getItem("light")
                                                                                )
                                                                                    ? ""
                                                                                    : "#5F6163",
                                                                                borderColor: JSON.parse(
                                                                                    localStorage.getItem("light")
                                                                                )
                                                                                    ? ""
                                                                                    : "#5F6163",
                                                                            }}
                                                                            onClick={
                                                                                opendropdown2
                                                                                    ? (e) => {
                                                                                        handledropdown("Amount", "dropdown2");
                                                                                        setopendropdown2(false);
                                                                                    }
                                                                                    : (e) => {
                                                                                        handledropdown("Total", "dropdown2");
                                                                                        setopendropdown2(true);
                                                                                    }
                                                                            }
                                                                        >
                                                                            <p>{opendropdown2 ? "Total" : "Amount"}</p>
                                                                            <img
                                                                                className="dropdown-icon"
                                                                                src={downarrow}
                                                                                alt=""
                                                                            />
                                                                        </div>

                                                                        <div className="trading-flex-pair">
                                                                            <input
                                                                                className="trade-box-part2"
                                                                                style={{
                                                                                    backgroundColor: JSON.parse(
                                                                                        localStorage.getItem("light")
                                                                                    )
                                                                                        ? ""
                                                                                        : "#17181F",
                                                                                }}
                                                                                type="text"
                                                                                placeholder="0.00"
                                                                                value={sellinput}
                                                                                onChange={(e) =>
                                                                                    validateText(e.target.value, "sell")
                                                                                }
                                                                            />
                                                                            <p className="trade-box-part3">
                                                                                {orderNameCoinSell}
                                                                            </p>
                                                                            {/* <p className="trade-box-part3">{`${
                            selecteddrop2 === "Total" &&
                            Object.keys(selectedPair).length > 0
                              ? selectedPair.quote
                              : selecteddrop2 === "Amount" &&
                                Object.keys(selectedPair).length > 0
                              ? selectedPair.base
                              : ""
                          }`}</p> */}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="submit-trade-btn1"
                                                                        onClick={(e) => BuyCoinFunction("sell")}
                                                                    >
                                                                        <p>Sell {coinType}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }
                                                {
                                                    window.innerWidth > 900 &&
                                                    <>
                                                        <div
                                                            className="buy-section"
                                                            style={{
                                                                borderColor: JSON.parse(localStorage.getItem("light"))
                                                                    ? ""
                                                                    : "#5F6163",
                                                            }}
                                                        >
                                                            <div
                                                                className="trade-head-div"
                                                                style={{
                                                                    backgroundColor: JSON.parse(
                                                                        localStorage.getItem("light")
                                                                    )
                                                                        ? ""
                                                                        : "#17181F",
                                                                }}
                                                            >
                                                                <p
                                                                    className="trade-head-inner"
                                                                    style={{
                                                                        backgroundColor: JSON.parse(
                                                                            localStorage.getItem("light")
                                                                        )
                                                                            ? ""
                                                                            : "#17181F",
                                                                    }}
                                                                >
                                                                    Buy
                                                                </p>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <div
                                                                        className="small-btn-1"
                                                                        style={{
                                                                            cursor:
                                                                                selecteddrop1 === "Total"
                                                                                    ? ""
                                                                                    : "not-allowed",
                                                                            borderColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#5F6163",
                                                                        }}
                                                                        onClick={() =>
                                                                            selecteddrop1 === "Total"
                                                                                ? handleperc(100, "buy")
                                                                                : ""
                                                                        }
                                                                    >
                                                                        100%
                                                                    </div>
                                                                    <div
                                                                        className="small-btn-1"
                                                                        style={{
                                                                            borderColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#5F6163",
                                                                        }}
                                                                    >
                                                                        Custom
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="trade-head-div-2"
                                                                style={{
                                                                    backgroundColor: JSON.parse(
                                                                        localStorage.getItem("light")
                                                                    )
                                                                        ? ""
                                                                        : "#17181F",
                                                                }}
                                                            >
                                                                <p className="head-2"
                                                                    style={{ fontSize: "9px" }}
                                                                >Avbl:</p>
                                                                <>
                                                                    <p className="head-2">
                                                                        {buyValue == undefined
                                                                            ? Number(0.0)?.toFixed(4)
                                                                            : Number(buyValue)?.toFixed(4)} &nbsp;
                                                                        {AvailbleBalanceBuy}
                                                                    </p>
                                                                </>
                                                            </div>
                                                            <div
                                                                className="trade-box-1"
                                                            //   style={{
                                                            //     backgroundColor: JSON.parse(
                                                            //       localStorage.getItem("light")
                                                            //     )
                                                            //       ? ""
                                                            //       : "#17181F",
                                                            //     borderColor: JSON.parse(
                                                            //       localStorage.getItem("light")
                                                            //     )
                                                            //       ? ""
                                                            //       : "#5F6163",
                                                            //   }}
                                                            >
                                                                <p className="trade-box-part1">Price</p>
                                                                <div className="trading-flex-pair">
                                                                    <p className="trade-box-part2">Market</p>
                                                                    <p className="trade-box-part3">{PriceAvailble}</p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="trade-box-1"
                                                            //   style={{
                                                            //     backgroundColor: JSON.parse(
                                                            //       localStorage.getItem("light")
                                                            //     )
                                                            //       ? ""
                                                            //       : "#17181F",
                                                            //     borderColor: JSON.parse(
                                                            //       localStorage.getItem("light")
                                                            //     )
                                                            //       ? ""
                                                            //       : "#5F6163",
                                                            //   }}
                                                            >
                                                                <div
                                                                    className="trade-box-part1-new"
                                                                    // style={{
                                                                    //   backgroundColor: JSON.parse(
                                                                    //     localStorage.getItem("light")
                                                                    //   )
                                                                    //     ? ""
                                                                    //     : "#17181F",
                                                                    //   borderColor: JSON.parse(
                                                                    //     localStorage.getItem("light")
                                                                    //   )
                                                                    //     ? ""
                                                                    //     : "#5F6163",
                                                                    // }}
                                                                    // onClick={(e) => handledrop("dropdown1")}

                                                                    onClick={
                                                                        opendropdown1
                                                                            ? (e) => {
                                                                                handledropdown("Amount", "dropdown1");
                                                                                setopendropdown1(false);
                                                                            }
                                                                            : (e) => {
                                                                                handledropdown("Total", "dropdown1");
                                                                                setopendropdown1(true);
                                                                            }
                                                                    }
                                                                >
                                                                    <p>{opendropdown1 ? "Total" : "Amount"}</p>
                                                                    {/* <p>{selecteddrop1}</p> */}
                                                                    <img
                                                                        className="dropdown-icon"
                                                                        src={downarrow}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="trading-flex-pair">
                                                                    <input
                                                                        className="trade-box-part2"
                                                                        type="text"
                                                                        placeholder="0.00"
                                                                        value={buyinput}
                                                                        onChange={(e) =>
                                                                            validateText(e.target.value, "buy")
                                                                        }
                                                                    />
                                                                    <p className="trade-box-part3">
                                                                        {orderNameCoinBuy}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="submit-trade-btn"
                                                                onClick={(e) => BuyCoinFunction("buy")}
                                                            >
                                                                <p>Buy {coinType}</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="sell-section"
                                                            style={{
                                                                backgroundColor: JSON.parse(
                                                                    localStorage.getItem("light")
                                                                )
                                                                    ? ""
                                                                    : "#17181F",
                                                            }}
                                                        >
                                                            <div
                                                                className="trade-head-div"
                                                                style={{
                                                                    backgroundColor: JSON.parse(
                                                                        localStorage.getItem("light")
                                                                    )
                                                                        ? ""
                                                                        : "#17181F",
                                                                }}
                                                            >
                                                                <p className="trade-head-inner">Sell</p>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <div
                                                                        className="small-btn-1"
                                                                        style={{
                                                                            cursor:
                                                                                selecteddrop2 === "Total"
                                                                                    ? "not-allowed"
                                                                                    : "",
                                                                            borderColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#5F6163",
                                                                        }}
                                                                        onClick={() =>
                                                                            selecteddrop2 === "Total"
                                                                                ? ""
                                                                                : handleperc(100, "sell")
                                                                        }
                                                                    >
                                                                        100%
                                                                    </div>
                                                                    <div
                                                                        className="small-btn-1"
                                                                        style={{
                                                                            borderColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#5F6163",
                                                                        }}
                                                                    >
                                                                        Custom
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="trade-head-div-2">
                                                                <p className="head-2"
                                                                    style={{ fontSize: "9px" }}
                                                                >Avbl:</p>
                                                                <>
                                                                    <p className="head-2">
                                                                        {sellValue == undefined
                                                                            ? Number(0.0)?.toFixed(4)
                                                                            : Number(sellValue)?.toFixed(4)}{" "}&nbsp;
                                                                        {AvailbleBalanceSell}
                                                                    </p>
                                                                </>
                                                            </div>
                                                            <div
                                                                className="trade-box-1"
                                                                style={{
                                                                    backgroundColor: JSON.parse(
                                                                        localStorage.getItem("light")
                                                                    )
                                                                        ? ""
                                                                        : "#17181F",
                                                                    borderColor: JSON.parse(
                                                                        localStorage.getItem("light")
                                                                    )
                                                                        ? ""
                                                                        : "#5F6163",
                                                                }}
                                                            >
                                                                <p className="trade-box-part1">Price</p>
                                                                <p className="trade-box-part2">Market</p>
                                                                <p className="trade-box-part3">{PriceAvailble}</p>
                                                            </div>
                                                            <div
                                                                className="trade-box-1"
                                                                style={{
                                                                    backgroundColor: JSON.parse(
                                                                        localStorage.getItem("light")
                                                                    )
                                                                        ? ""
                                                                        : "#17181F",
                                                                    borderColor: JSON.parse(
                                                                        localStorage.getItem("light")
                                                                    )
                                                                        ? ""
                                                                        : "#5F6163",
                                                                }}
                                                            >
                                                                <div
                                                                    className="trade-box-part1-new"
                                                                    style={{
                                                                        borderColor: JSON.parse(
                                                                            localStorage.getItem("light")
                                                                        )
                                                                            ? ""
                                                                            : "#5F6163",
                                                                        borderColor: JSON.parse(
                                                                            localStorage.getItem("light")
                                                                        )
                                                                            ? ""
                                                                            : "#5F6163",
                                                                    }}
                                                                    onClick={
                                                                        opendropdown2
                                                                            ? (e) => {
                                                                                handledropdown("Amount", "dropdown2");
                                                                                setopendropdown2(false);
                                                                            }
                                                                            : (e) => {
                                                                                handledropdown("Total", "dropdown2");
                                                                                setopendropdown2(true);
                                                                            }
                                                                    }
                                                                >
                                                                    <p>{opendropdown2 ? "Total" : "Amount"}</p>
                                                                    <img
                                                                        className="dropdown-icon"
                                                                        src={downarrow}
                                                                        alt=""
                                                                    />
                                                                </div>

                                                                <div className="trading-flex-pair">
                                                                    <input
                                                                        className="trade-box-part2"
                                                                        style={{
                                                                            backgroundColor: JSON.parse(
                                                                                localStorage.getItem("light")
                                                                            )
                                                                                ? ""
                                                                                : "#17181F",
                                                                        }}
                                                                        type="text"
                                                                        placeholder="0.00"
                                                                        value={sellinput}
                                                                        onChange={(e) =>
                                                                            validateText(e.target.value, "sell")
                                                                        }
                                                                    />
                                                                    <p className="trade-box-part3">
                                                                        {orderNameCoinSell}
                                                                    </p>
                                                                    {/* <p className="trade-box-part3">{`${
                            selecteddrop2 === "Total" &&
                            Object.keys(selectedPair).length > 0
                              ? selectedPair.quote
                              : selecteddrop2 === "Amount" &&
                                Object.keys(selectedPair).length > 0
                              ? selectedPair.base
                              : ""
                          }`}</p> */}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="submit-trade-btn1"
                                                                onClick={(e) => BuyCoinFunction("sell")}
                                                            >
                                                                <p>Sell {coinType}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </>
                                        <>
                                            {/* {opendropdown1 === true ? (
                      <div
                        className="dropdownbox"
                        style={{
                          borderColor: JSON.parse(localStorage.getItem("light"))
                            ? ""
                            : "#5F6163",
                        }}
                      >
                        <p
                          className="drop-value"
                          style={{
                            borderColor: JSON.parse(
                              localStorage.getItem("light")
                            )
                              ? ""
                              : "#5F6163",
                          }}
                          onClick={(e) => handledropdown("Total", "dropdown1")}
                        >
                          Total
                        </p>
                        <p
                          className="drop-value"
                          style={{
                            borderColor: JSON.parse(
                              localStorage.getItem("light")
                            )
                              ? ""
                              : "#5F6163",
                          }}
                          onClick={(e) => handledropdown("Amount", "dropdown1")}
                        >
                          Amount
                        </p>
                      </div>
                    ) : (
                      ""
                    )} */}
                                        </>
                                        <>
                                            {/* {opendropdown2 === true ? (
                      <div
                        className="dropdownbox1"
                        style={{
                          borderColor: JSON.parse(localStorage.getItem("light"))
                            ? ""
                            : "#5F6163",
                        }}
                      >
                        <p
                          className="drop-value"
                          style={{
                            borderColor: JSON.parse(
                              localStorage.getItem("light")
                            )
                              ? ""
                              : "#5F6163",
                          }}
                          onClick={(e) => handledropdown("Total", "dropdown2")}
                        >
                          Total
                        </p>
                        <p
                          className="drop-value"
                          style={{
                            borderColor: JSON.parse(
                              localStorage.getItem("light")
                            )
                              ? ""
                              : "#5F6163",
                          }}
                          onClick={(e) => handledropdown("Amount", "dropdown2")}
                        >
                          Amount
                        </p>
                      </div>
                    ) : (
                      ""
                    )} */}
                                        </>
                                    </>
                                ) : selectedNav === "TokenOptions" ? (
                                    <>
                                        <div>
                                            <div
                                                className="market-nav"
                                                style={{
                                                    borderColor: JSON.parse(localStorage.getItem("light"))
                                                        ? ""
                                                        : "#5F6163",
                                                }}
                                            >
                                                <p className="market-head-text">Select Platform</p>
                                            </div>
                                            <div
                                                className="pair-search-box-new"
                                                style={{
                                                    borderColor: JSON.parse(localStorage.getItem("light"))
                                                        ? ""
                                                        : "#5F6163",
                                                }}
                                            >
                                                <div className="market-search-box">
                                                    <img
                                                        className="market-search-img"
                                                        style={{ opacity: marketsearch !== "" ? 1 : "" }}
                                                        src={
                                                            JSON.parse(localStorage.getItem("light"))
                                                                ? searchicon
                                                                : searchicon1
                                                        }
                                                        alt=""
                                                    />
                                                    <input
                                                        className="market-searchbox"
                                                        style={{
                                                            backgroundColor: JSON.parse(
                                                                localStorage.getItem("light")
                                                            )
                                                                ? ""
                                                                : "#17181F",
                                                            color: JSON.parse(localStorage.getItem("light"))
                                                                ? ""
                                                                : "#E7E7E7",
                                                        }}
                                                        type="text"
                                                        placeholder="Search Platform..."
                                                        value={marketsearch}
                                                        onChange={(e) => setmarketsearch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="market-menu">
                                                {TerminalData?.filter((item) =>
                                                    item.name
                                                        ?.toLowerCase()
                                                        .includes(marketsearch.toLowerCase())
                                                )?.map((each, id) => {
                                                    return (
                                                        <div
                                                            className={
                                                                JSON.parse(localStorage.getItem("light"))
                                                                    ? "each-market-box"
                                                                    : "each-market-box-dark"
                                                            }
                                                            // onClick={(e) => handlerouting(each, "Market")}
                                                            style={{
                                                                borderColor: JSON.parse(
                                                                    localStorage.getItem("light")
                                                                )
                                                                    ? ""
                                                                    : "#5F6163",
                                                            }}
                                                        >
                                                            <div
                                                                className="market-menu-box"
                                                                style={{
                                                                    opacity: each.name === "Terminal" ? 1 : "0.4",
                                                                }}
                                                            >
                                                                <img
                                                                    className="market-menu-img"
                                                                    src={each.img}
                                                                    alt=""
                                                                />
                                                                <p className="market-menu-name">
                                                                    {each.name}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                ) : selectedNav === "Market" ? (
                                    <>
                                        <div>
                                            <div
                                                className="market-nav"
                                                style={{
                                                    borderColor: JSON.parse(localStorage.getItem("light"))
                                                        ? ""
                                                        : "#5F6163",
                                                }}
                                            >
                                                <p className="market-head-text">Select Market</p>
                                            </div>
                                            <div
                                                className="pair-search-box-new"
                                                style={{
                                                    borderColor: JSON.parse(localStorage.getItem("light"))
                                                        ? ""
                                                        : "#5F6163",
                                                }}
                                            >
                                                <div className="market-search-box">
                                                    <img
                                                        className="market-search-img"
                                                        style={{ opacity: marketsearch !== "" ? 1 : "" }}
                                                        src={
                                                            JSON.parse(localStorage.getItem("light"))
                                                                ? searchicon
                                                                : searchicon1
                                                        }
                                                        alt=""
                                                    />
                                                    <input
                                                        className="market-searchbox"
                                                        style={{
                                                            backgroundColor: JSON.parse(
                                                                localStorage.getItem("light")
                                                            )
                                                                ? ""
                                                                : "#17181F",
                                                            color: JSON.parse(localStorage.getItem("light"))
                                                                ? ""
                                                                : "#E7E7E7",
                                                        }}
                                                        type="text"
                                                        placeholder="Search Markets..."
                                                        value={marketsearch}
                                                        onChange={(e) => setmarketsearch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="market-menu">
                                                {marketMenu?.filter((item) =>
                                                    item.menuItem
                                                        ?.toLowerCase()
                                                        .includes(marketsearch.toLowerCase())
                                                )?.map((each, id) => {
                                                    return (
                                                        <div
                                                            className={
                                                                JSON.parse(localStorage.getItem("light"))
                                                                    ? "each-market-box"
                                                                    : "each-market-box-dark"
                                                            }
                                                            onClick={(e) => handlerouting(each, "Market")}
                                                            style={{
                                                                borderColor: JSON.parse(
                                                                    localStorage.getItem("light")
                                                                )
                                                                    ? ""
                                                                    : "#5F6163",
                                                            }}
                                                        >
                                                            <div
                                                                className="market-menu-box"
                                                                style={{
                                                                    opacity: marketType.id === each.id ? 1 : "",
                                                                }}
                                                            >
                                                                <img
                                                                    className="market-menu-img"
                                                                    src={each.menuIcons}
                                                                    alt=""
                                                                />
                                                                <p className="market-menu-name">
                                                                    {each.menuItem}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                ) : selectedNav === "Routing" ? (
                                    <>
                                        <div>
                                            <div
                                                className="market-nav"
                                                style={{
                                                    borderColor: JSON.parse(localStorage.getItem("light"))
                                                        ? ""
                                                        : "#5F6163",
                                                }}
                                            >
                                                <p className="market-head-text">Select Routing</p>
                                            </div>
                                            <div
                                                className="pair-search-box-new"
                                                style={{
                                                    borderColor: JSON.parse(localStorage.getItem("light"))
                                                        ? ""
                                                        : "#5F6163",
                                                }}
                                            >
                                                <div className="market-search-box">
                                                    <img
                                                        className="market-search-img"
                                                        style={{ opacity: routingsearch !== "" ? 1 : "" }}
                                                        src={
                                                            JSON.parse(localStorage.getItem("light"))
                                                                ? searchicon
                                                                : searchicon1
                                                        }
                                                        alt=""
                                                    />
                                                    <input
                                                        className="market-searchbox"
                                                        style={{
                                                            backgroundColor: JSON.parse(
                                                                localStorage.getItem("light")
                                                            )
                                                                ? ""
                                                                : "#17181F",
                                                            color: JSON.parse(localStorage.getItem("light"))
                                                                ? ""
                                                                : "#E7E7E7",
                                                        }}
                                                        type="search"
                                                        placeholder="Search Destinations..."
                                                        value={routingsearch}
                                                        onChange={(e) => setroutingsearch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="market-menu">
                                                {routingMenu.map((each, id) => {
                                                    return (
                                                        <div
                                                            className={
                                                                JSON.parse(localStorage.getItem("light"))
                                                                    ? "each-market-box"
                                                                    : "each-market-box-dark"
                                                            }
                                                            onClick={(e) => handlerouting(each, "Routing")}
                                                            style={{
                                                                borderColor: JSON.parse(
                                                                    localStorage.getItem("light")
                                                                )
                                                                    ? ""
                                                                    : "#5F6163",
                                                            }}
                                                        >
                                                            <div
                                                                className="market-menu-box"
                                                                style={{
                                                                    opacity: marketType.id === each.id ? 1 : "",
                                                                }}
                                                            >
                                                                <img
                                                                    className="market-menu-img"
                                                                    src={each.menuIcons}
                                                                    alt=""
                                                                />
                                                                <p className="market-menu-name">
                                                                    {each.menuItem}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                ) : selectedNav === "Vaults" ? (
                                    <VaultDrawer />
                                ) : (
                                    <div className="add-vault-menu">
                                        <AddVault />
                                    </div>
                                )}
                            </>
                            {/* <ToastContainer position="top-right" newestOnTop /> */}
                        </div>


                    </div>
                </div>
                {/* <div onMouseDown={(e) => setopenpairs(false)}>
            {openpairs === true ?
            <div className='pagemask'>
                <div className='pairs-popup' onMouseDown={e => e.stopPropagation()}>
                    <div className='pairs-search-bar' style={{opacity : pairssearch !== '' ? 1 :''}}>
                        <img className='pairs-search-img' src={searchicon} alt='' />
                        <input className='pairs-searchbox' type='search' placeholder='' 
                        value={pairssearch} onChange={(e) => setpairssearch(e.target.value)}/>
                    </div> 
                    <div className='pairs-group'>
                        <>
                        {
                            pairsList.length >0 ? 
                            <>
                            <div className='all-coins-data'>
                                {pairsList.map((each, id) => {
                                    return(
                                        <div>
                                            <div className='coins-box' style={{border: each.coinCode === coin.coinCode ? 
                                            '0.5px solid black' : ''}} 
                                            onClick={() => handlesetcoin(each)}>
                                                <img className={coin === each ? 'pairs-coin-img pairs-coin-img-active': 'pairs-coin-img'} src={each.image} alt='' />
                                                <p className='pairs-coin-text'>{each.coinCode}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div>
                                <>
                                {Object.keys(coin).length > 0 && coin.pairs.length > 0?
                                <div className='each-coin-pairs'>
                                {
                                coin.pairs.filter((element) => {
                                return(
                                    element.pair.toLowerCase().includes(pairssearch.toLowerCase())
                                )
                                })
                                .map((each, id) => {
                                    return(
                                        <div className='all-pairs' onClick={() => handleSelectPair(each)}>
                                            <div className='pair-boxes'>
                                                <div className='each-pair-box'>
                                                    <img className='each-pair-img' src={each.image} alt="" />
                                                    <p className='each-pair-head'>{each.pair}</p>
                                                </div>
                                                <div className='each-pair-box1'>
                                                    <p className='each-pair-head'>--</p>
                                                </div>
                                                <div className='each-pair-box1'>
                                                    <p className='each-pair-head'>--</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) }
                                </div>
                                :''}
                                </>
                            </div>
                            </>
                            :''
                        }
                        </>
                    </div>
                </div>
            </div>
        :''}
        </div> */}
                {/* <div onMouseDown={(e) => setopenrouting('')}>
            {openrouting === 'Market' ?
            <div className='pagemask' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' : 'rgba(133, 131, 131, 0.75)'}}>
                <div className='market-popup' onMouseDown={e => e.stopPropagation()} style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F'}}>
                    <div className='pairs-search-bar' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', opacity : JSON.parse(localStorage.getItem('light'))? 1: marketsearch !== '' ? 1 :'', 
                borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                        <img className='pairs-search-img' src={searchicon} alt='' />
                        <input className='pairs-searchbox' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', color:JSON.parse(localStorage.getItem('light'))? '' : '#E7E7E7'}} type='search' placeholder='Search Markets....' 
                        value={marketsearch} onChange={(e) => setmarketsearch(e.target.value)}/>
                    </div> 
                    <div className='market-menu'>
                        {marketMenu.map((each, id) => {
                            return(
                                <div className='each-market-box' onClick={e => handlerouting(each,'Market')}
                                style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                    <div className='market-menu-box' style={{opacity: marketType.id === each.id ? 1 : ''}}>
                                        <img className='market-menu-img' src={each.menuIcons} alt='' />
                                        <p className='market-menu-name'>{each.menuItem}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            :openrouting === 'Routing'?
            <div className='pagemask' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' : 'rgba(133, 131, 131, 0.75)'}}>
                <div className='routing-popup' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F'}} onMouseDown={e => e.stopPropagation()}>
                    <div className='pairs-search-bar' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', opacity : JSON.parse(localStorage.getItem('light'))? 1: routingsearch !== '' ? 1 :'', 
                borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                        <img className='pairs-search-img' src={searchicon} alt='' />
                        <input className='pairs-searchbox' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', color:JSON.parse(localStorage.getItem('light'))? '' : '#E7E7E7'}} type='search' placeholder='Crypto Routing Engines....' 
                        value={routingsearch} onChange={(e) => setroutingsearch(e.target.value)}/>
                    </div> 
                    <div className='market-menu'>
                        {routingMenu.map((each, id) => {
                            return(
                                <div className='each-routing-box' onClick={e => handlerouting(each,'Routing')} style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                    <div className='market-menu-box' style={{opacity: routingType.id === each.id ? 1 : ''}}>
                                        <img className='market-menu-img' src={each.menuIcons} alt='' />
                                        <p className='market-menu-name'>{each.menuItem}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            :''}
        </div> */}



                {
                    tostSuceess ?


                        <div className="ToastNofication">
                            <img src={cross} className="crossClose" onClick={() => settostSuceess(false)} />
                            <div className="toastImageSection">
                                <img className="tradeSucess" src={success} />
                                <p>{SuceesMessage?.order_type} {SuceesMessage?.type} Order Successfull</p>
                            </div>
                            <h1>Filled exchange {SuceesMessage?.order_type} {SuceesMessage?.type} order for {SuceesMessage?.amount} {CoinListed?.split("/")?.[0]} by using {CoinListed?.split("/")?.[1]}</h1>

                        </div>
                        : ""}
            </div >
        </>
    );
};

export default TerminalRoutingPage;
