import React, {useState, useContext, useEffect} from 'react';
import { MainContext } from '../Context/context';
import './addVault.scss';
import vaults from '../../assets/images/navbar/vaults.svg';
import vaults1 from '../../assets/images/navbar/vaults1.svg';
import {GetAppsRegistered} from '../../services/actionservice';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {GetFromBalance, VaultTransaction} from '../../services/actionservice';
import img1 from '../../assets/images/img1.svg';

function AddVault() {
    const {selectedNav, setselectedNav, selectVaultApp, setselectVaultApp, addVault, setaddVault,
        selectedVaultCoin, setSelectedVaultCoin} = useContext(MainContext)
    const [from, setfrom] = useState("");
    const [to, setto] = useState("");
    const [userRegisteredApps, setuserRegisteredApps] = useState([])
    const [appsearchterm, setappsearchterm] = useState("")
    const [isLoadingapp, setisLoadingapp] = useState(false)
    const [isLoadingtransapi, setisLoadingtransapi] = useState(false)
    const [selectedApp, setselectedApp] = useState({})
    const [terminalApp, setterminalApp] = useState({})
    const [frombalance, setfrombalance] = useState('TBD')
    const [transactionSuccess, settransactionSuccess] = useState({})

    const handlePercCal = (value) => {
        if(Object.keys(selectedApp).length > 0){
            let balance = frombalance === 'TBD' ? 0 : frombalance
            let final = (balance * value)/100
            final = handleDecimal(final)
            setfrom(final)
        }
        // handleApi(final, true)
    }

    const handleDecimal = (num) => {
        num = num?.toString(); 
        num = num?.slice(0, (num.indexOf("."))+5); 
        return Number(num);
      }

    const handleInput = (value, type, pathdat) => {
        let t = value
        let newValue = (t === "" ? "0.00" : t!=="" && t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 5)) : t;
        if(type === 'from'){
            setfrom(newValue)
        }else if(type === 'to'){
            setto(newValue)
        }
        
    }

    const handleApi = (value) => {
        setisLoadingtransapi(true)
        let obj = {
            email: JSON.parse(localStorage.getItem("user"))["data"].email,
            token: JSON.parse(localStorage.getItem("user")).idToken,
            app_code: selectedApp.app_code,
            ter_app_code: localStorage.getItem('selectedappcode'),
            coin: selectedVaultCoin.coinMetadata.symbol,
            amount: value
        }
        VaultTransaction(obj).then(response => {
            let result = response.data
            console.log("result",result)
            if(result.status){
                settransactionSuccess(result)
                let newdata = selectedVaultCoin
                newdata.vault = result.credit.updated_balance
                setSelectedVaultCoin(newdata)

                setisLoadingtransapi(false)
            }else{
                setisLoadingtransapi(false)
            }
        })
    } 

    const handleBack = () => {
        if(selectedNav === 'Vaults' && selectVaultApp === true){
            setselectVaultApp(false)
        }else if(selectedNav === 'Vaults' && Object.keys(addVault).length > 0){
            setaddVault({})
        }
    }

    const resetform = () => {
        setfrom('')
        setselectedApp({})
        setfrombalance('TBD')
        settransactionSuccess({})
        setselectVaultApp(false)
    }

    const handleSelectApp = (each) => {
        let obj = {
            app_code: each.app_code,
            email: JSON.parse(localStorage.getItem("user"))["data"].email,
            include_coins: [selectedVaultCoin.coinMetadata.symbol]
        }
        GetFromBalance(obj).then(response => {
            let result = response.data
            if(result.status){
                setfrombalance(result.coins_data[0].coinValue)
                setfrom('')
                setselectedApp(each)
                setselectVaultApp(false)
            }
        })
        
    }

    const handleApps = () => {
        let usermail = JSON.parse(localStorage.getItem("user"))["data"].email;
        GetAppsRegistered(usermail).then(response => {
            let result = response.data;
            if(result.status){
                setuserRegisteredApps(result.userApps)
                setisLoadingapp(false)
            }
        })
    }

    const handleClose = () => {
        setaddVault({})
        setSelectedVaultCoin({})
        setfrom('')
        setselectedApp({})
        setfrombalance('TBD')
        settransactionSuccess({})
        setselectVaultApp(false)
        
    }
console.log("cxzc",terminalApp)
    useEffect(() => {
        let usermail = JSON.parse(localStorage.getItem("user"))["data"].email;
        GetAppsRegistered(usermail).then(response => {
            let result = response.data;
            if(result.status){
                let userApp = result.userApps.filter(x =>{
                    return(x.app_code === localStorage.getItem('selectedappcode'))
                })  
                setterminalApp(userApp[0])
            }
        }) 
    }, [])

    useEffect(() => {
        if(selectVaultApp === true){
            setisLoadingapp(true)
            handleApps()
        }
    }, [selectVaultApp])

  return (
    
    <div className='add-vault-master' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F'}}>
        <div className='add-vault-logo-box' onClick={(e) => setselectedNav('Masters')}>
            <img className='add-vault-main-img' src={JSON.parse(localStorage.getItem('light'))? vaults : vaults1} alt='' />
            <p className='add-vault-main-name'>Vaults</p>
        </div>
        {Object.keys(transactionSuccess).length === 0 ?
        <div>
            <div>
                <>
                {selectVaultApp === false ?
                    <div className='menu1'>
                        <div className='menuItem1'>
                            <div style={{display: 'flex', justifyContent:'space-between'}}>
                                    <p className='subhead'>{`From ${selectedVaultCoin?.coinMetadata?.symbol} Vault`}</p>
                                    <p className='subhead1'>{`Balance: ${frombalance}`}</p>
                            </div>
                            <div className='transaction' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                    <input className="transc" key="from" type="number" id="from" name="from" placeholder="0.00" 
                                    value={from} onChange={e => handleInput(e.target.value, 'from')} 
                                    disabled={Object.keys(selectedApp).length >0 ? '' : "disabled"}
                                    style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', cursor:Object.keys(selectedApp).length >0 ? '' : 'not-allowed', color:JSON.parse(localStorage.getItem('light'))? '' :'#E7E7E7',}}
                                    />
                                    <>
                                    {Object.keys(selectedApp).length === 0?
                                        <div className='coin1' key="assets" 
                                        onClick={(e) => setselectVaultApp(true)}
                                        style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F',
                                        borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}
                                        >
                                            <p className="coinsymbolsmall">Select App </p>
                                        </div>
                                        :
                                        <div className='coin' key="assets" style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}} onClick={(e) => setselectVaultApp(true)}>
                                            <img className="coinimgsmall" src={selectedApp.app_icon} alt="" />
                                            <p className="coinsymbolsmall">{selectedApp.app_name}</p>
                                        </div> 
                                    }
                                    </>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between'}} >
                                    <p className='perc'
                                    style={{opacity: Object.keys(selectedApp).length ===0 ? 0.25 : 1, cursor:Object.keys(selectedApp).length ===0  ? '' : 'pointer', backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}
                                    onClick={() => handlePercCal(25)} disabled={Object.keys(selectedApp).length ===0 ? "disabled": ''}
                                    >
                                        25%
                                    </p>
                                    <p className='perc' 
                                    style={{opacity: Object.keys(selectedApp).length ===0 ? 0.25 : 1, cursor:Object.keys(selectedApp).length ===0  ? '' : 'pointer', backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}
                                    onClick={() => handlePercCal(50)} disabled={Object.keys(selectedApp).length ===0 ? "disabled": ''}
                                    >
                                        50%
                                    </p>
                                    <p className='perc' 
                                    style={{opacity: Object.keys(selectedApp).length ===0 ? 0.25 : 1, cursor:Object.keys(selectedApp).length ===0  ? '' : 'pointer', backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}
                                    onClick={() => handlePercCal(100)} disabled={Object.keys(selectedApp).length ===0 ? "disabled": ''}
                                    >
                                        100%
                                    </p>
                            </div>
                            <div 
                            // style={{opacity: to === 'Loading..' ? 0.25 : 1}}
                            >
                                <div 
                                style={{display: 'flex', justifyContent:'space-between', marginTop: '15%'}}
                                >
                                        <p className='subhead'>{`To ${selectedVaultCoin?.coinMetadata?.symbol} Vault`}</p>
                                        <p className='subhead1'>{`Balance: ${handleDecimal(selectedVaultCoin.vault, 5)}`}</p>
                                </div>
                                <div className='transaction' style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                        <input className="transc" type="number" key="to" id="to" name="to" placeholder="0.00" 
                                        value={from} onChange={e => handleInput(e.target.value, 'from')} 
                                        disabled="disabled"
                                        style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', cursor:'not-allowed', color:JSON.parse(localStorage.getItem('light'))? '' :'#E7E7E7',}}
                                        />
                                        <div className='coin' key="coin" style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F', borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                            <img className="coinimgsmall" src={Object.keys(terminalApp).length>0 ? terminalApp.app_icon : ''} alt="" />
                                            <p className="coinsymbolsmall">{Object.keys(terminalApp).length>0 ? terminalApp.app_name : ''}</p>
                                        </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    :
                    <div className='select-box'>
                        <p className='select-title-head'>Select From App</p>
                        <div className='search-card' style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                            <div className='searchboxasset' style={{cursor:isLoadingapp?'not-allowed':'', borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                <input
                                        className="search"
                                        type="search"
                                        placeholder="Search Apps..."
                                        value={appsearchterm}
                                        onChange={(e) => setappsearchterm(e.target.value)}
                                        disabled={isLoadingapp? 'disabled':''}
                                        style={{backgroundColor: JSON.parse(localStorage.getItem('light'))? '' :'#17181F',
                                    color:JSON.parse(localStorage.getItem('light'))? '' :'#E7E7E7'}}
                                    />
                            </div>
                            <>
                            {isLoadingapp?
                            <>
                                <div className={JSON.parse(localStorage.getItem('light'))?'coin-box': 'coin-box-dark'} style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                <Skeleton  style={{height:'30px', width:'30px', borderRadius:'50%'}}/>
                                <Skeleton  style={{marginLeft:'15px', height:'30px', width:'150px'}}/>
                                </div>
                                <div className={JSON.parse(localStorage.getItem('light'))?'coin-box': 'coin-box-dark'} style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                <Skeleton  style={{height:'30px', width:'30px', borderRadius:'50%'}}/>
                                <Skeleton  style={{marginLeft:'15px', height:'30px', width:'150px'}}/>
                                </div>
                                <div className={JSON.parse(localStorage.getItem('light'))?'coin-box': 'coin-box-dark'} style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                <Skeleton  style={{height:'30px', width:'30px', borderRadius:'50%'}}/>
                                <Skeleton  style={{marginLeft:'15px', height:'30px', width:'150px'}}/>
                                </div>
                                <div className={JSON.parse(localStorage.getItem('light'))?'coin-box': 'coin-box-dark'} style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                <Skeleton  style={{height:'30px', width:'30px', borderRadius:'50%'}}/>
                                <Skeleton  style={{marginLeft:'15px', height:'30px', width:'150px'}}/>
                                </div>
                                <div className={JSON.parse(localStorage.getItem('light'))?'coin-box': 'coin-box-dark'} style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                                <Skeleton  style={{height:'30px', width:'30px', borderRadius:'50%'}}/>
                                <Skeleton  style={{marginLeft:'15px', height:'30px', width:'150px'}}/>
                                </div>
                                
                               
                            </>
                            :
                                <div className='assetlistcard'>
                                {
                                    userRegisteredApps.filter((element) => {
                                    return(
                                        element.app_name.toLowerCase().includes(appsearchterm.toLowerCase())
                                    )
                                    }).map((each, id) => {
                                    return(
                                        <div className={JSON.parse(localStorage.getItem('light'))?'coin-box': 'coin-box-dark'} style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}} key={id} onClick={() => handleSelectApp(each)}>
                                        <img key={id} className='coin-Image' src={each.app_icon} alt="" />
                                        <p key={id} className='coin-Title'>{each.app_name}</p>
                                        </div>
                                    )
                                    })
                                }
                                </div>
                            }
                            </>
                        </div>
                    </div>
                }
                </>
            </div>
            <div className="footer1">
                <div className='back' key="back" onClick={(e) => handleBack()}>Back</div>
                <div className='tradebtn' key="tradebtn" style={{opacity:from !== '' ? 1 : 0.25, color:from !== '' ? 'white' :'', cursor:from !== '' ? 'pointer' :''}} 
                onClick={(e) =>  from !== '' ? handleApi(from) : ''}
                >Confirm</div>
            </div>
            <>
            {isLoadingtransapi?
            <div className='terminallogo'>
                <img className='terminallogoimg' src={img1} alt=''/>
            </div>
            :''}
            </>
        </div>
        :
        <div>
            <div className='success-vault-box'>
            <p className='success-text'>{`Congratulations. You Have Successully transferred ${from} ${selectedVaultCoin?.coinMetadata?.symbol} from ${selectedApp.app_name} to ${terminalApp.app_name}`}</p>

            <p className='success-head'>{`New ${selectedVaultCoin?.coinMetadata?.symbol} Balance`}</p>

            <div className='transaction' style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                <p className='updated-value'>{handleDecimal(transactionSuccess.debit.updated_balance)}</p>
                <div className='coin' key="coin">
                    <img className="coinimgsmall" src={selectedApp.app_icon} alt="" />
                    <p className="coinsymbolsmall">{selectedApp.app_name}</p>
                </div>
            </div>

            <p className='success-head'>{`New ${selectedVaultCoin?.coinMetadata?.symbol} Balance`}</p>
            <div className='transaction' style={{borderColor:JSON.parse(localStorage.getItem('light'))? '' :'#5F6163'}}>
                <p className='updated-value'>{handleDecimal(transactionSuccess.credit.updated_balance)}</p>
                <div className='coin' key="coin">
                    <img className="coinimgsmall" src={Object.keys(terminalApp).length>0 ? terminalApp.app_icon : ''} alt="" />
                    <p className="coinsymbolsmall">{Object.keys(terminalApp).length>0 ? terminalApp.app_name : ''}</p>
                </div>
            </div>
            </div>
            <div className="footer1">
                <div className='back' key="back" onClick={(e) => handleClose()}>close</div>
                <div className='tradebtn' key="tradebtn" style={{opacity:1, color:'white', cursor:'pointer'}} 
                onClick={(e) => resetform()}
                >Vaults</div>
            </div>
        </div>
        }
    </div>
   
  )
}

export default AddVault