import React, { useState } from "react";
import "./Registration.scss";
import { ToastContainer, toast } from "react-toastify";
import mainlogo from "../../assets/images/uploadedlogo.svg";
import "react-toastify/ReactToastify.min.css";
const BinanceLogin = () => {
    const [emial, setemail] = useState("")
    const [step, setstep] = useState(1)
    const generateCaptcha = () => {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let captcha = "";
        for (let i = 0; i < 6; i++) {
            captcha += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return captcha;
    };
    const [captcha, setCaptcha] = useState(generateCaptcha());
    const refreshCaptcha = () => setCaptcha(generateCaptcha());
    const [inputcapture, setinputcapture] = useState("")
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const isLengthValid = password.length >= 8 && password.length <= 128;
    const hasNumber = /\d/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);

    const invalidcaptuer = () => {
        if (inputcapture == captcha) {
            setstep(4)
        }
        else {
            toast("Invaild captcha", {
                type: "error",
            });
        }
    }

    const validateEmail = () => {
        if (emial == "") {
            return
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailRegex.test(emial)) {
            setstep(2)
        }
        else {
            toast("Invaild Email", {
                type: "error",
            });
        }
    };
    const postai = () => {

    }
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setemail(value);
        validateEmail(value);
    };

    const controller = () => {

        switch (step) {
            case 5:
                return (
                    <>
                        <div className="login-box">
                            <img className="img" src={mainlogo} />
                            <h2 className="title">Welcome aboard!</h2>
                            {/* <p className="subtitle">Do you have an inviter? (Optional)</p> */}
                            {/* <div className="options">
                                    <button
                                        className={`option ${selectedOption === "yes" ? "selected" : ""}`}
                                        onClick={() => setSelectedOption("yes")}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className={`option ${selectedOption === "no" ? "selected" : ""}`}
                                        onClick={() => setSelectedOption("no")}
                                    >
                                        No
                                    </button>
                                </div> */}
                            <button className="button">Next</button>
                        </div>

                    </>
                )
            case 4:
                return (
                    <>

                        <div className="login-box">
                            <img className="img" src={mainlogo} />
                            <h2 className="title">Create a password</h2>

                            <label className="label">Password</label>
                            <div className="input-container">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter your password"
                                    className="input"
                                />
                                <button className="toggle-button" onClick={togglePasswordVisibility}>
                                    {showPassword ? "🙈" : "👁"}
                                </button>
                            </div>

                            <ul className="password-requirements">
                                <li className={isLengthValid ? "valid" : "invalid"}>✔ 8 to 128 characters</li>
                                <li className={hasNumber ? "valid" : "invalid"}>✔ At least 1 number</li>
                                <li className={hasUpperCase ? "valid" : "invalid"}>✔ At least 1 uppercase letter</li>
                                <li className={hasSpecialChar ? "valid" : "invalid"}>✔ At least 1 special character (@$!%*?&)</li>
                            </ul>

                            <button className="button"
                                onClick={postai}
                                disabled={!(isLengthValid && hasNumber && hasUpperCase)}>
                                Next
                            </button>
                        </div>

                    </>
                )

            case 2:
                return (
                    <>

                        <div className="login-box">
                            <img className="img" src={mainlogo} />
                            <h2 className="title">Security Verification</h2>
                            <p className="description">Please complete the security verification to continue.</p>

                            <label className="label">Enter Captcha</label>
                            <div className="captcha-container">
                                <div className="captcha-image">{captcha}</div>
                                <button className="refresh-button" onClick={refreshCaptcha}>↻</button>
                            </div>
                            <input type="text" value={inputcapture}
                                onChange={(e) => setinputcapture(e.target.value)}
                                placeholder="Enter the characters you see" className="input" />

                            <button className="button" onClick={invalidcaptuer}>Next</button>
                            <p className="resend" onClick={refreshCaptcha}>Can't see the captcha? Refresh</p>
                        </div>

                    </>
                )
            case 3:
                return (
                    <>

                        <div className="login-box">
                            <img className="img" src={mainlogo} />
                            <h2 className="title">Verify your email</h2>
                            <p className="description">
                                A 6-digit code has been sent to <strong>kamal@nvestbank.com</strong>. Please enter it within the next 30 minutes.
                            </p>
                            <label className="label">Verification Code</label>
                            <div className="input-container">
                                <input
                                    type="text"
                                    placeholder=" "
                                    className="input"
                                />
                                <span className="code-status">Code Sent</span>
                            </div>
                            <button className="button" onClick={() => setstep(4)}>Next</button>
                            <p className="resend">Didn't receive the code?</p>
                        </div>


                    </>
                )
                break
            case 1:
                return (
                    <>
                        <div className="login-box">
                            <img className="img" src={mainlogo} />
                            <h2 className="title">Welcome to TokenOptions</h2>
                            <label className="label">Email</label>
                            <input
                                type="text"
                                placeholder="Email"
                                className="input"
                                value={emial}
                                onChange={(e) => setemail(e.target.value)}
                            />
                            <div className="checkbox-container">
                                <input type="checkbox" id="terms" className="checkbox" />
                                <label htmlFor="terms" className="checkbox-label">
                                    By creating an account, I agree to TokenOptions <br />
                                    <a href="#" className="link">Terms of Service</a> and <a href="#" className="link">Privacy Policy</a>
                                </label>
                            </div>
                            <button className="button"
                                onClick={validateEmail}
                            >Next</button>
                            <div className="separator">or</div>
                            <button className="social-button google">
                                <img
                                    style={{
                                        height: "20px"
                                    }}
                                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="" className="icon" />
                                Continue with Google
                            </button>
                            <button className="social-button apple">
                                <img style={{
                                    height: "20px"
                                }} src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg" alt="" className="icon" />
                                Continue with Apple
                            </button>
                            <button className="social-button telegram"

                            >
                                <img style={{
                                    height: "20px"
                                }} src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg" alt="" className="icon" />
                                Continue with Telegram
                            </button>
                        </div>

                    </>
                )
                break;

            default:
                break;
        }
    }

    return (
        <div className="registation-dashboard">
            <ToastContainer position="top-right" newestOnTop />
            {controller()}
        </div>
    );
};

export default BinanceLogin;
